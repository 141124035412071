<template>
  <div
    class="w-full md:w-[50%] p-10 rounded-3xl border-[0.1px] border-RegentGrey"
  >
    <h4 class="font-semibold text-xl">Password Reset</h4>
    <!--
          @submit.prevent="changeCurrentPassword"
       -->
    <div class="flex flex-col w-full">
      <div class="space-y-4 md:space-y-8 mb-2 md:my-4">
        <div>
          <div class="flex justify-between items-center pt-4">
            <label for="old_password" class="text-sm text-BluishGrey"
              >Old password</label
            >
            <div
              class="cursor-pointer relative"
              :class="
                !showPassword &&
                'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
              "
              @click="showPassword = !showPassword"
            >
              <img
                :src="eyeIcon"
                alt="eye icon"
              />
            </div>
          </div>
          <!--
                @keyup.enter="changeCurrentPassword"
             -->
          <Input
            :type="showPassword ? 'text' : 'password'"
            name="old_password"
            id="old_password"
            class="border-b py-0.5 font-sans placeholder:font-urbanist text-sm md:text-[14px] items-center w-full border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 placeholder:text-OlsoGrey font-bold"
            v-model="old_password"
          />
        </div>
        <div>
          <div class="flex justify-between items-center pt-4">
            <label for="password" class="text-sm text-BluishGrey"
              >New password</label
            >
            <div
              class="cursor-pointer relative"
              :class="
                !showNewPassword &&
                'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
              "
              @click="showNewPassword = !showNewPassword"
            >
              <img
                :src="eyeIcon"
                alt="eye icon"
              />
            </div>
          </div>
          <!--
                 @keyup.enter="changeCurrentPassword"
             -->
          <Input
            :type="showNewPassword ? 'text' : 'password'"
            name="password"
            id="password"
            class="border-b py-0.5 font-sans placeholder:font-urbanist text-sm md:text-[14px] items-center w-full border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 placeholder:text-OlsoGrey font-bold"
            v-model="password"
          />
          <p class="text-red-500 text-[10px] mt-1">{{ passwordError }}</p>
        </div>
        <div>
          <div class="flex justify-between items-center pt-4">
            <label for="password_2" class="text-sm text-BluishGrey"
              >Confirm New Password</label
            >
            <div
              class="cursor-pointer relative"
              :class="
                !showConfirmNewPassword &&
                'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
              "
              @click="showConfirmNewPassword = !showConfirmNewPassword"
            >
              <img
                :src="eyeIcon"
                alt="eye icon"
              />
            </div>
          </div>
          <!--
   @keyup.enter="changeCurrentPassword"
             -->
          <Input
            :type="showConfirmNewPassword ? 'text' : 'password'"
            name="password_2"
            id="password_2"
            class="border-b py-0.5 font-sans placeholder:font-urbanist text-sm md:text-[14px] items-center w-full border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 placeholder:text-OlsoGrey font-bold"
            v-model="password_2"
          />
          <p v-if="passwordError2" class="text-red-500 text-sm mt-2">
            {{ passwordError2 }}
          </p>
        </div>
        <!--

            :class="!isLoginDisabled && 'hover:bg-DodgerBlue'"
            :disabled="isLoginDisabled"
           -->
        <BaseButton
          :disabled="isPasswordNotCorrect || !canSubmit || isLoading"
          @click="changeCurrentPassword"
          class="custom-button font-semibold bg-ResolutionBlue text-sm md:text-[14px] rounded-3xl text-white mt-8 py-4 duration-500 w-56"
        >
          <span v-if="isLoading">
            <LoadingSpinner />
          </span>
          <span v-else>Change password</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import useCheckPassword from "@/composables/useCheckPassword";
import { push } from "notivue";
import Input from "@/components/main/ui/Input.vue"
import BaseButton from "@/components/main/ui/BaseButton.vue"
import eyeIcon from "@/assets/icons/password_eye.svg"

const { "user/changePassword": changePassword } = mapActions();

const isLoading = ref(false);
const old_password = ref("");
const password = ref("");
const password_2 = ref("");
const showPassword = ref(false);
const showNewPassword = ref(false);
const showConfirmNewPassword = ref(false);
const passwordError = ref("");
const passwordError2 = ref("");

const canSubmit = computed(
  () => !!old_password.value && !!password.value && !!password_2.value
);
const isPasswordNotCorrect = useCheckPassword(password, passwordError);

const changeCurrentPassword = async () => {
  if (password.value !== password_2.value) {
    passwordError2.value = "Passwords do not match";
  } else {
    try {
      isLoading.value = true;
      await changePassword({
        old_password: old_password.value,
        password: password.value,
        password_2: password_2.value,
      });

      push.success("Password changed successfully");
      old_password.value = "";
      password.value = "";
      password_2.value = "";
    } catch (error) {
      if (error?.response?.data?.message) {
        push.error(error.response.data.message);
      } else {
        push.error("Error resetting password");
      }
    } finally {
      isLoading.value = false;
    }
  }
};
</script>
