<template>
  <div
    class="absolute left-0 top-full mt-3 z-10 bg-white w-full h-[102px] py-4 px-6 rounded-lg border border-BluishGrey flex flex-col gap-[10px] overflow-y-scroll no-scrollbar"
  >
    <div
      v-if="devices?.length > 0"
      v-for="device in devices"
      :key="device.id"
      @click="selectDevice(device)"
      class="flex items-center gap-4 cursor-pointer"
    >
      <div class="w-[30px] h-[30px] flex justify-center items-center">
        <img :src="smallDeviceImage" :alt="device.name" />
      </div>

      <p class="text-DarkJungleGreen text-sm">
        {{ device.category }}
      </p>
    </div>
    <div v-else class="flex justify-center items-center h-full">
      <p class="text-DarkJungleGreen text-xl font-medium text-center">
        No devices found
      </p>
    </div>
  </div>
</template>

<script setup>
import smallDeviceImage from "@/assets/icons/small_device_image1.svg";

const props = defineProps({
  devices: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["setDevice"]);

const selectDevice = (value) => {
  emit("setDevice", value);
};
</script>
