<template>
  <div class="text-center mt-24">
    <div v-if="email" class="mb-8">
      <div class="mb-5">
        <div
          class="bg-WhiteLilac w-[80px] h-[80px] mx-auto rounded-full flex justify-center items-center overflow-hidden"
        >
          <img
            :src="emailIcon"
            alt="email icon"
            class="mx-auto animate-email-switch"
          />
        </div>
      </div>

      <div>
        <h1 class="text-black text-2xl font-bold mb-2">Email sent</h1>
        <p class="text-OlsoGrey text-base max-w-[305px] mx-auto">
          We've sent a link to create a new password to {{ email }}. If it's not
          in your inbox, check your spam/junk folder.
        </p>
      </div>
    </div>

    <div v-else class="mb-8">
      <p class="text-OlsoGrey text-lg font-semibold max-w-[305px] mx-auto">
        Invalid email
      </p>
    </div>

    <router-link to="/auth/sign-in">
      <p class="text-ResolutionBlue text-sm font-bold mb-8">Back to login</p>
    </router-link>

    <div class="bg-ResolutionBlue/10 rounded-2xl py-3 px-7">
      <p class="text-ResolutionBlue text-xs max-w-[217px] mx-auto">
        If you did not initiate the request, kindly contact us at
        <span class="font-semibold">support@turbomedics.com</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import emailIcon from "@/assets/icons/email.svg";

const route = useRoute();
const email = ref("");
if (typeof route.query.email === "string") {
  email.value = route.query.email.replace("%", "@");
} else {
  email.value = "";
}
</script>
