<template>
  <div class="dropdown relative inline-block">
    <img
      :src="profilePicture"
      class="w-6 h-6 md:w-10 md:h-10 rounded-full object-cover cursor-pointer flex items-center"
      alt=""
    />

    <div
      class="dropdown-content hidden absolute right-0 bg-white min-w-[160px] shadow-2xl z-30"
    >
      <router-link
        v-if="!isAdmin"
        to="/patient/settings/account-information"
        class="text-black py-[12px] px-[16px] block hover:text-ResolutionBlue hover:bg-WhiteLilac"
        >Profile</router-link
      >
      <router-link
        v-if="!isAdmin"
        to="/patient/settings"
        class="text-black py-[12px] px-[16px] block hover:text-ResolutionBlue hover:bg-WhiteLilac"
        >Settings</router-link
      >
      <BaseButton
        @click="showLogoutModal = true"
        class="w-full text-left text-black py-[12px] px-[16px] block hover:text-ResolutionBlue hover:bg-WhiteLilac"
      >
        <span>Log out</span>

        <LogoutModal :show="showLogoutModal" @close="showLogoutModal = false" />
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { mapGetters } from "@/hooks/mapStore";
import LogoutModal from "../ui/modals/LogoutModal.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue"

const {
  "auth/getIsAdmin": isAdmin,
  "user/getUserProfilePicture": profilePicture,
} = mapGetters();

const showLogoutModal = ref(false);
</script>

<style scoped>
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
