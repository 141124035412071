<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar"
    >
      <div class="w-full border px-2 md:px-5 py-6 rounded-xl">
        <div class="flex justify-between items-center gap-3 mb-3">
          <p class="font-urbanist-regular font-bold">My Appointments</p>

          <BaseButton
            v-if="appointments?.length > 0"
            class="bg-ResolutionBlue py-1.5 px-10 md:py-2 md:px-12 rounded-[40px] text-white text-xs md:text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
            @click="openAppointmentModal"
          >
            Book Now
          </BaseButton>
        </div>

        <!-- Always show the tabs -->
        <Tab :status="currentStatus" @changeStatus="changeStatus" />

        <!-- Loading state -->
        <div
          v-if="isLoading"
          class="w-full h-64 flex justify-center items-center"
        >
          <img
            :src="loadingIcon"
            alt="loading icon"
            class="animate-spin"
          />
        </div>

        <!-- Error state -->
        <div v-else-if="isError" class="text-center text-red-500">
          An error occurred while fetching appointments.
        </div>

        <!-- Empty state -->
        <div
          v-else-if="appointments?.length === 0"
          class="text-center py-4 text-DavyGrey"
        >
          No {{ currentStatus }} appointments available.
        </div>

        <!-- Appointments Table -->
        <div v-else>
          <div class="overflow-x-auto custom-scrollbar md:mt-8">
            <table class="w-full">
              <thead>
                <tr>
                  <th
                    class="min-w-[130px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Health Center
                  </th>
                  <th
                    class="min-w-[80px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    City
                  </th>
                  <th
                    class="min-w-[220px] py-3 pr-2 text-left text-sm font-bold text-[#25282B]"
                  >
                    Address
                  </th>
                  <th
                    class="min-w-[130px] py-3 text-left text-sm font-bold text-[#25282B] relative flex items-center gap-1.5"
                  >
                    <span>HC Phone</span>
                    <span
                      class="bg-ResolutionBlue w-3 h-3 rounded-full text-white text-[0.625rem] flex justify-center items-center cursor-pointer relative group"
                    >
                      i
                      <span
                        class="hidden group-hover:block absolute -bottom-1 right-1/2 translate-x-1/2 translate-y-full bg-GhostWhite w-36 py-2 px-3 rounded-lg text-[#25282B] text-center"
                      >
                        Health center phone
                      </span>
                    </span>
                  </th>
                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Type
                  </th>
                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Status
                  </th>
                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Date
                  </th>
                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Time
                  </th>
                  <th class="min-w-[100px] py-3">
                    <span class="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="appointment in appointments"
                  :key="appointment.id"
                  class="border-t border-gray-200"
                >
                  <td class="min-w-[100px] py-3 text-DavyGrey text-sm">
                    {{ appointment?.health_centre?.name }}
                  </td>
                  <td class="min-w-[80px] py-3 text-DavyGrey text-sm">
                    {{ appointment?.city_of_centre }}
                  </td>
                  <td
                    class="min-w-[220px] py-3 pr-2 text-DavyGrey text-sm cursor-pointer"
                    :title="appointment?.health_centre?.address"
                  >
                    {{ appointment?.health_centre?.address }}
                  </td>
                  <td
                    class="min-w-[130px] py-3 text-DavyGrey text-sm cursor-pointer"
                  >
                    {{ appointment?.health_centre?.phone }}
                  </td>
                  <td
                    class="min-w-[100px] py-3 text-DavyGrey text-sm capitalize"
                  >
                    {{ appointment?.type }}
                  </td>
                  <td
                    class="min-w-[100px] py-3 text-sm first-letter:capitalize"
                    :class="statusStyles(appointment?.status)"
                  >
                    {{ appointment?.status }}
                  </td>
                  <td class="min-w-[200px] py-3 text-DavyGrey text-sm">
                    {{
                      appointment?.date && dateFormatter_2(appointment?.date)
                    }}
                  </td>
                  <td class="min-w-[100px] py-3 text-DavyGrey text-sm">
                    {{ appointment?.time && appointment?.time.slice(0, 5) }}
                  </td>
                  <td
                    v-if="
                      appointment?.status !== 'Cancelled' &&
                      appointment?.status !== 'Expired' &&
                      appointment?.status !== 'Completed'
                    "
                    class="min-w-[100px] py-3 text-DavyGrey"
                  >
                    <v-menu open-on-hover>
                      <template v-slot:activator="{ props }">
                        <BaseButton
                          v-bind="props"
                          class="hover:bg-WhiteLilac p-2 text-2xl md:text-3xl font-bold rounded flex justify-center items-center transition-colors duration-300"
                        >
                          <img :src="optionButton" alt="three dots" />
                        </BaseButton>
                      </template>
                      <AppointmentActionBox
                        @edit="setAppointmentModal(appointment)"
                        @cancel="cancelCurrentAppointment(appointment)"
                      />
                    </v-menu>
                  </td>
                </tr>
                <tr v-if="appointments?.length === 0">
                  <td colspan="9" class="text-center py-4 text-DavyGrey">
                    No {{ currentStatus }} appointments available.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex items-center py-4">
            <BaseButton
              class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
              :disabled="currentPage === 1"
              @click="currentPage--"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </BaseButton>

            <div class="flex space-x-2 mx-2">
              <BaseButton
                v-for="page in visiblePages"
                :key="page"
                @click="currentPage = page"
                :class="[
                  'w-[38px] h-[38px] flex justify-center items-center border rounded-[4px]',
                  currentPage === page
                    ? 'bg-ResolutionBlue text-white'
                    : 'bg-gray-100 text-black',
                ]"
              >
                {{ page }}
              </BaseButton>
            </div>
            <BaseButton
              class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
              :disabled="currentPage === totalPagesComputed"
              @click="currentPage++"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </BaseButton>

            <span class="text-gray-500 ml-2"
              >of {{ totalPagesComputed }} pages</span
            >
          </div>
        </div>
      </div>

      <CancelAppointmentModal
        v-if="showCancelModal"
        :appointment="selectedAppointment"
        @close="closeCancelModal"
        @cancel-appointment="handleCancelAppointment"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject, watchEffect } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { mapActions, mapGetters } from "vuex";
import { push } from "notivue";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import Tab from "@/components/main/patient/appointment/Tab.vue";
import AppointmentActionBox from "@/components/main/patient/appointment/AppointmentActionBox.vue";
import optionButton from "@/assets/icons/option-button.svg";
import CancelAppointmentModal from "@/components/main/patient/appointment/CancelAppointmentModal.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import loadingIcon from "@/assets/icons/loading-01.svg"

const route = useRoute();
const store = useStore();

// Refs
const currentPage = ref(1);
const appointmentsPerPage = ref(10);
const currentStatus = ref(route.query.status || "all");
const appointments = ref([]);

// Modal-related refs
const showCancelModal = ref(false);
const selectedAppointment = ref(null);

// mapActions to dispatch actions from Vuex
const { fetchPatientAppointments, cancelAppointment } = mapActions(
  "appointment",
  ["fetchPatientAppointments", "cancelAppointment"]
);

// mapGetters to retrieve state from Vuex
const { getAppointmentsByStatus, totalPages } = mapGetters("appointment", [
  "getAppointmentsByStatus",
  "totalPages",
]);

const totalPagesComputed = computed(
  () => store.getters["appointment/totalPages"]
);

const openAppointmentModal = inject("openAppointmentModal");
const setAppointmentModalVal = inject("setAppointmentModalVal");

// Fetch appointments using Vuex actions
const fetchAppointments = async ({ status, page, perPage }) => {
  try {
    const appointmentsData = await store.dispatch(
      "appointment/fetchPatientAppointments",
      {
        status,
        page,
        perPage,
      }
    );
    return appointmentsData;
  } catch (error) {
    push.error("Error fetching appointments:", error);
  }
};

// Use Vue Query to manage fetching appointments
const { data, isLoading, isError } = useQuery({
  queryKey: ["appointments", currentStatus, currentPage],
  queryFn: () =>
    fetchAppointments({
      status: currentStatus.value,
      page: currentPage.value,
      perPage: appointmentsPerPage.value,
    }),
});

watchEffect(() => {
  if (data.value) {
    appointments.value = data.value; // Update the appointments ref with data from Vue Query
  }
});

const changeStatus = async (status) => {
  currentStatus.value = status;
  currentPage.value = 1;
};

const statusStyles = (status) => {
  const statusClasses = {
    Scheduled: "text-BrightBlue",
    Pending: "text-OrangePeel",
    Completed: "text-DarkMint",
    Cancelled: "text-DeepCarminePink",
    Expired: "text-DeepCarminePink",
  };
  return statusClasses[status] || "text-DavyGrey";
};

const handleCancelAppointment = async (appointmentId) => {
  // Close the cancel modal
  closeCancelModal();
  // Refresh the appointment list
};

const setAppointmentModal = (appointment) => {
  setAppointmentModalVal({
    type: "edit",
    country: appointment?.health_centre?.country || "",
    state: appointment?.state_of_centre || "",
    city: appointment?.city_of_centre || "",
    center: appointment?.health_centre?.name || "",
    appointmentReason: appointment?.reason_for_appointment || "",
    address: appointment?.health_centre?.address || "",
    date: appointment?.date || "",
    time: appointment?.time || "",
    appointmentId: appointment?.id || "",
  });

  openAppointmentModal(); // Open the modal after setting values
};

const visiblePages = computed(() => {
  const pages = [];
  for (let i = 1; i <= totalPages.value; i++) {
    pages.push(i);
  }
  return pages;
});

const cancelCurrentAppointment = (appointment) => {
  selectedAppointment.value = appointment;
  showCancelModal.value = true;
};

const closeCancelModal = () => {
  showCancelModal.value = false;
  selectedAppointment.value = null;
};
</script>
