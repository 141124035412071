<template>
  <div
    class="fixed top-0 left-0 z-[60] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[600px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex justify-between items-center mb-6">
          <p>Edit Post</p>
          <div
            class="flex flex-row items-center rounded-sm bg-WhiteLilac p-1.5"
          >
            <span
              @click="toggleTabs(1)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-4 py-1.5': openTab !== 1,
                'font-bold rounded-sm px-4 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 1,
              }"
            >
              Main</span
            >
            <span
              @click="toggleTabs(2)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-4 py-1.5': openTab !== 2,
                'font-bold rounded-sm px-4 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 2,
              }"
            >
              Body</span
            >
            <span
              @click="toggleTabs(3)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-4 py-1.5': openTab !== 3,
                'font-bold rounded-sm px-4 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 3,
              }"
            >
              Image</span
            >
          </div>
          <div class="self-start cursor-pointer" @click="closeEditBlogModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>

        <div>
          <div :class="{ hidden: openTab !== 1, block: openTab === 1 }">
            <EditTitleAndAuthor :post="post" />
          </div>
          <div :class="{ hidden: openTab !== 2, block: openTab === 2 }">
            <EditBody :post="post" />
          </div>
          <div :class="{ hidden: openTab !== 3, block: openTab === 3 }">
            <EditImage :post="post" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import EditTitleAndAuthor from "./EditTitleAndAuthor.vue";
import EditBody from "./EditBody.vue";
import EditImage from "./EditImage.vue";

const props = defineProps(["post"]);

const closeEditBlogModal = inject("closeEditBlogModal");

const openTab = ref(1);

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};
</script>

<script>
export default {
  name: "EditBlogPost",
};
</script>
