import axios from "@/api";

const state = {
  newDevice: null,
};

const getters = {
  setAddNewDevice: (state) => {
    return state.newDevice;
  },
};

const mutations = {
  setAddNewDevice(state, newDeviceData) {
    state.newDevice = newDeviceData;
  },
};

const actions = {
  async addNewDevice({ commit }, deviceDetails) {
    try {
      const response = await axios.post("/data/medical_devices", deviceDetails);
      commit("setAddNewDevice", response.data);
      // console.log("New device:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error adding new device:", error);
      throw error;
    }
  },

  async getAllDevices({ rootState }) {
    const isAdmin = rootState.auth.isAdmin;

    if (isAdmin) {
      const response = await axios.get("/data/medical_devices");
      return response.data;
    }
  },

  async linkDevice(_, payload) {
    const response = await axios.post(
      `/data/medical_device/link/${payload.patientId}`,
      {
        imei: payload.imei,
        sn: payload.sn,
      }
    );
    return response.data;
  },

  async unlinkDevice(_, payload) {
    const response = await axios.delete(
      `/data/medical_device/delete/${payload}`
    );
    return response.data;
  },

  async getMedicalDevices({ rootGetters }) {
    const isAdmin = rootGetters["auth/getIsAdmin"];

    if (!isAdmin) {
      const response = await axios.get("/data/patient/medical_device");
      return response.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
