<template>
  <div :class="inputClass">
    <label :for="label" class="px-2 text-[10px] md:text-xs text-OlsoGrey">
      {{ label }}
    </label>
    <input
      :id="label"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :type="type"
      class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 disabled:bg-transparent focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
      :placeholder="placeholder"
      :disabled="disabled"
      :max="max"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  label: String,
  modelValue: [String, Number],
  type: {
    type: String,
    default: "text",
  },
  placeholder: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  max: {
    type: String,
  },
});

const emit = defineEmits(["update:modelValue"]);

const inputClass = computed(() => ({
  'flex flex-col w-full pt-4 pb-3 border-b focus-within:border-b-0': true,
  'border-BlueChalk': props.modelValue && props.modelValue !== '',
  'border-red-500': !props.modelValue || props.modelValue === '',
}));
</script>
