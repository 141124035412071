import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const formatRelativeTime = (date) => {
  let relativeTime = dayjs().to(dayjs(date), true);
  relativeTime = relativeTime.replace("hours", "hrs")
                             .replace("minutes", "mins")
                             .replace("seconds", "secs")
                             .replace("hour", "hr")
                             .replace("minute", "min")
                             .replace("second", "sec");
  
  return relativeTime + " ago";
};

export default formatRelativeTime;
