<template>
    <Transition name="dropdown-wrapper">
      <slot></slot>
    </Transition>
  </template>
  
  <script setup></script>
  
  <style scoped>
  .dropdown-wrapper-enter-from,
  .dropdown-wrapper-leave-to {
    opacity: 0;
    translate: 0px -15px;
  }
  
  .dropdown-wrapper-enter-active,
  .dropdown-wrapper-leave-active {
    transition: all 0.3s ease;
  }
  </style>
  