import axios from "@/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchPatientRequests(_, page = 1) {
    const response = await axios.get("/data/patient/connections/list", {
      params: {
        page: page,
      },
    });
    return response.data;
  },

  async sendPatientInvite(_, payload) {
    const response = await axios.post(
      `/data/patient/connection/request/${payload.id}`,
      {
        relationship: payload.relationship,
      }
    );
    return response.data;
  },

  async acceptPatientRequest(_, payload) {
    const response = await axios.put(
      `/data/patient/connection/response/${payload.id}`,
      {
        response: payload.response,
      }
    );
    return response.data;
  },

  async unlinkPatientAccount(_, payload) {
    const response = await axios.delete(
      `/data/patient/connection/cancel/${payload.id}`
    );
    return response.data;
  },

  async cancelSentRequest(_, payload) {
    const response = await axios.delete(
      `/data/patient/connection/cancel/${payload.id}`
    );
    return response.data;
  },

  async getLinkedAccountVitals(_, payload) {
    const response = await axios.get(
      `/data/patient/tests/connected/${payload.id}`,
      {
        params: {
          test_type: payload.test_type,
          sort: payload.sort,
          time_period: payload.time_period,
        },
      }
    );
    return response.data;
  },

  async getLinkedCenterInfo(_, payload) {
    const response = await axios.get(
      `/data/patient/health_centre/${payload.id}`
    );
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
