<template>
  <div
    class="fixed top-0 left-0 z-[60] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar py-8"
  >
    <div
      class="w-full max-w-[1080px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <!-- <div class="absolute w-full h-full top-0 left-0 rounded-[38px]"></div> -->
      <div class="space-y-5">
        <div class="flex justify-end items-center mb-6">
          <div class="self-start cursor-pointer" @click="closeViewBlogModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="flex gap-2 md:gap-4 items-start">
          <span class="text-GunMetal text-2xl font-bold text-center"
            >{{ post?.title }}
          </span>
        </div>
        <div class="flex gap-2 md:gap-4 items-start">
          <div class="pt-8 w-full h-[300px] md:h-[200px] xl:h-[400px]">
            <img
              :src="post?.image"
              class="w-full h-full object-cover object-center rounded-tr-[100px] rounded-bl-[100px] md:rounded-tr-[120px] md:rounded-bl-[120px]"
              :alt="post?.image"
            />
          </div>
        </div>
        <div v-html="post?.body"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps(["post"]);

const closeViewBlogModal = inject("closeViewBlogModal");
</script>
