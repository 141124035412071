<template>
  <div
    class="fixed top-0 left-0 z-[75] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[1080px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div
        class="absolute w-full h-full top-0 left-0 rounded-[38px]"
        @click="closeDropdowns"
      ></div>
      <div>
        <div class="flex justify-between items-center mb-6">
          <div class="flex gap-2 md:gap-4 items-center">
            <span class="text-GunMetal text-base md:text-xl font-bold"
              >Test Center</span
            >
            <div
              class="bg-ResolutionBlue text-white text-xs md:text-sm font-bold py-[5px] px-2.5 md:py-[10px] md:px-5 flex justify-center items-center gap-[10px] rounded-[40px] cursor-pointer relative"
              @click="openAddNewPatientModal"
            >
              <img
                :src="addIcon"
                alt="add icon"
              />
              <span>New Patient</span>
            </div>
          </div>

          <div class="self-start cursor-pointer" @click="cancelTest">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>

        <p class="text-sm">
          Patient -
          <span class="font-semibold">{{
            selectedPatient.person.full_name
          }}</span>
        </p>

        <div class="flex flex-col md:flex-row gap-16 my-9">
          <div class="w-full md:w-1/2 flex flex-col gap-6">
            <div class="relative">
              <label for="testType" class="text-BluishGrey text-xs"
                >Test Type</label
              >
              <input
                type="text"
                id="testType"
                class="w-full outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold focus:border-ResolutionBlue focus:ring-2"
                :value="selectedTestType"
                @input="handleTestTypeInput"
                @focus="setTestTypeFocus"
              />
              <img
                :src="downCaretIcon"
                alt="down caret icon"
                class="absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer"
              />
              <TestTypesDropdown
                v-show="showTestTypesDropdown"
                :testTypes="testTypes"
                @setTestType="selectTestType"
              />
            </div>
          </div>

          <div class="w-full md:w-1/2">
            <div class="relative">
              <label for="facilityDevice" class="text-BluishGrey text-sm"
                >Facility Device</label
              >
              <div v-if="!selectedDevice.length > 0">
                <input
                  type="text"
                  id="facilityDevice"
                  class="w-full outline-none border-b border-b-BlueChalk text-DarkJungle text-sm font-bold"
                  :value="selectedDevice"
                  @focus="setFaciltyDevicesFocus"
                />
                <img
                  :src="downCaretIcon"
                  alt="down caret icon"
                  class="absolute top-2 right-0 cursor-pointer w-[10px] h-[10px]"
                  v-show="!selectedDevice"
                />
                <FacilityDeviceDropdown
                  v-show="showFaciltyDevicesDropdown"
                  :devices="allDevices"
                  @setDevice="selectDevice"
                />
              </div>

              <div class="bg-WhiteLilac rounded-lg p-3" v-else>
                <div
                  class="bg-white py-[10px] px-[14px] rounded-lg flex items-center gap-5"
                >
                  <div
                    class="bg-white rounded-sm border border-WhiteLilac py-2 px-1.5 flex justify-center items-center"
                  >
                    <img
                      :src="smallDeviceImage"
                      alt=""
                    />
                  </div>

                  <div>
                    <p class="text-BluishGrey text-xs">Blood Glucose</p>
                    <p class="text-OlsoGrey text-sm font-bold">
                      4G Blood Glucose Monitor
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isPending">
          <img
            :src="loadingIcon"
            alt="loader"
            class="animate-spin mx-auto my-4"
          />
        </div>

        <div class="flex justify-center items-center gap-[10px] relative">
          <BaseButton
            class="w-[147px] p-1.5 md:p-3 border border-ResolutionBlue text-ResolutionBlue rounded-[44px] text-center text-base font-semibold"
            @click="goBack"
          >
            Back
          </BaseButton>
          <BaseButton
            v-if="isDeviceLinked"
            class="bg-ResolutionBlue text-white w-[147px] p-1.5 md:p-3 border rounded-[44px] text-center text-base font-semibold"
            @click="handleOpenTestStepsModal"
          >
            Run test
          </BaseButton>
          <BaseButton
            v-else
            class="bg-Geyser text-MistBlue w-[147px] p-1.5 md:p-3 border rounded-[44px] text-center text-base font-semibold"
            @click="submitSelectedPatient"
          >
            Submit
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from "vue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import TestTypesDropdown from "../dropdowns/TestTypesDropdown.vue";
import FacilityDeviceDropdown from "../dropdowns/FacilityDeviceDropdown.vue";
import { allTestTypes } from "@/utils/mockData/testTypes";
import addIcon from "@/assets/icons/add_icon.svg"
import downCaretIcon from "@/assets/icons/down_caret.svg"
import smallDeviceImage from "@/assets/icons/small_device_image1.svg"
import loadingIcon from "@/assets/icons/loading-01.svg"

import BaseButton from "@/components/main/ui/BaseButton.vue";

const queryClient = useQueryClient();

const openSearchPatientModal = inject("openSearchPatientModal");
const closeTestModal = inject("closeModal");
const openAddNewPatientModal = inject("openAddNewPatientModal");
const openTestStepsModal = inject("openTestStepsModal");
const selectedPatient = inject("selectedPatient");
const currentPatientAndDevice = inject("currentPatientAndDevice");
const {
  "device/getAllDevices": getAllDevices,
  "device/linkDevice": linkDevice,
} = mapActions();
const testTypes = ref(allTestTypes);
const selectedTestType = ref("");
const showTestTypesDropdown = ref(false);
const selectedDevice = ref("");
const showFaciltyDevicesDropdown = ref(false);
const isDeviceLinked = ref(false);
const { data: allDevices } = useQuery({
  queryKey: ["devices"],
  queryFn: () => getAllDevices(),
});
const { isPending, mutate } = useMutation({
  mutationFn: (data) => linkDevice(data),
  onError: (error) => {
    if (error) {
      selectedDevice.value = "";
      isDeviceLinked.value = false;
      push.error(error.response.data.message);
    }
  },
  onSuccess: (_) => {
    queryClient.invalidateQueries({ queryKey: ["devices"] });
    isDeviceLinked.value = true;
    push.success("Device linked successfully");
  },
});
const selectTestType = (value) => {
  selectedTestType.value = value;
  showTestTypesDropdown.value = false;
};
const selectDevice = (value) => {
  if (selectedTestType.value) {
    currentPatientAndDevice.value.device = value;
    const { category, sn, imei } = value;
    selectedDevice.value = category;
    showFaciltyDevicesDropdown.value = false;
    mutate({
      imei,
      sn,
      patientId: selectedPatient.value.id,
    });
  } else {
    push.info("Please select test type");
  }
};
const handleTestTypeInput = (e) => {
  selectedTestType.value = e.target.value;
};
const setTestTypeFocus = () => {
  showTestTypesDropdown.value = true;
  showFaciltyDevicesDropdown.value = false;
};
const setFaciltyDevicesFocus = () => {
  showFaciltyDevicesDropdown.value = true;
  showTestTypesDropdown.value = false;
};
const submitSelectedPatient = () => {
  if (!selectedTestType.value || !selectedDevice.value) {
    return push.info("Please fill all fields");
  }
  const t = testTypes.value.find(
    (testType) => testType.name === selectedTestType.value
  );
  if (!t) {
    push.info("Please select valid test type");
  }
};
const closeDropdowns = () => {
  showTestTypesDropdown.value = false;
  showFaciltyDevicesDropdown.value = false;
};
const goBack = () => {
  closeDropdowns();
  closeTestModal();
  openSearchPatientModal();
};
const cancelTest = () => {
  closeDropdowns();
  closeTestModal();
  selectedTestType.value = "";
  selectedDevice.value = "";
};
const handleOpenTestStepsModal = () => {
  cancelTest();
  openTestStepsModal();
};
</script>
