<template>
  <div
    class="absolute left-0 top-full z-10 bg-white w-full h-[140px] py-5 px-6 rounded-lg border border-BluishGrey flex flex-col gap-[10px] overflow-y-scroll no-scrollbar"
  >
    <div
      v-for="(testType, index) in testTypes"
      :key="index"
      @click="testType.isAvailable && selectTestType(testType.name)"
      class="flex items-center gap-4 cursor-pointer"
      :class="!testType.isAvailable && 'opacity-50'"
    >
      <div
        class="w-[30px] h-[30px] bg-LavendaMist rounded-full flex justify-center items-center"
      >
        <img :src="testType.image" :alt="testType.name" />
      </div>

      <p class="text-DarkJungleGreen text-sm">
        {{ testType.name }}
      </p>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  testTypes: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["setTestType"]);

const selectTestType = (testType) => {
  emit("setTestType", testType);
};
</script>
