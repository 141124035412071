<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <div v-if="patient?.glucose_records?.length > 0">
            <div
              class="overflow-x-auto border rounded-2xl shadow py-4 md:py-8 px-8 space-y-5"
            >
              <div class="flex items-center justify-between">
                <p class="font-semibold">Blood Glucose Records</p>
                <!-- <div class="flex items-center space-x-4">
                    <div class="toggle-switch">
                      <div
                        :class="{ 'switch-state': true, chart: isChartActive }"
                        @click="toggleSwitch('chart')"
                      >
                        <ChartIcon />
                      </div>
                      <div
                        :class="{ 'switch-state': true, table: isTableActive }"
                        @click="toggleSwitch('table')"
                      >
                        <TableIcon />
                      </div>
                    </div>
                    <div class="border-[0.1px] rounded-lg px-3 py-1.5">
                      Last week
                    </div>
                  </div> -->
              </div>
              <div>
                <div v-if="isChartActive">Chart</div>
                <div v-if="isTableActive" class="space-y-6">
                  <div class="flex flex-row items-center justify-between">
                    <div
                      class="w-fit rounded-3xl px-4 py-2 bg-OrangePeel text-white text-xs"
                    >
                      Avg:
                      <span class="font-semibold">{{ average_high }}mg/dl</span>
                    </div>
                    <div class="flex space-x-4">
                      <div class="flex items-center space-x-2">
                        <div class="bg-DodgerBlue w-1 h-1 rounded-full"></div>
                        <span class="text-DodgerBlue text-[10px] lg:text-xs"
                          >Low</span
                        >
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="bg-DarkMint w-1 h-1 rounded-full"></div>
                        <span class="text-DarkMint text-[10px] lg:text-xs"
                          >Normal</span
                        >
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="bg-OrangePeel w-1 h-1 rounded-full"></div>
                        <span class="text-OrangePeel text-[10px] lg:text-xs"
                          >Average High</span
                        >
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="bg-ArtyClickRed w-1 h-1 rounded-full"></div>
                        <span class="text-ArtyClickRed text-[10px] lg:text-xs"
                          >High</span
                        >
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="bg-ArtyClickRed w-1 h-1 rounded-full"></div>
                        <span class="text-ArtyClickRed text-[10px] lg:text-xs"
                          >High</span
                        >
                      </div>
                    </div>
                  </div>
                  <table class="w-full">
                    <thead>
                      <tr>
                        <th
                          class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                        >
                          Glucose Level
                        </th>
                        <th
                          class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                        >
                          Meal
                        </th>
                        <th
                          class="min-w-[100px] md:min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                        >
                          Date
                        </th>
                        <th
                          class="min-w-[100px] md:min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                        >
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="font-light"
                        v-for="(record, index) in paginatedPatientRecords"
                        :key="index"
                      >
                        <td
                          class="min-w-[130px] py-5 border-t border-gray-200 text-sm font-medium"
                          :class="[
                            record.glucose_level < hypoglycaemic
                              ? 'text-DodgerBlue'
                              : record.glucose_level >= hypoglycaemic &&
                                record.glucose_level <= normal
                              ? 'text-DarkMint'
                              : record.glucose_level > normal &&
                                record.glucose_level <= average_high
                              ? 'text-OrangePeel'
                              : record.glucose_level >= high
                              ? 'text-ArtyClickRed'
                              : '',
                          ]"
                        >
                          {{ record.glucose_level }} mg/dl
                        </td>
                        <td
                          class="min-w-[130px] py-5 border-t border-gray-200 text-MistBlue text-sm"
                        >
                          {{ record.before_meal ? "Befor meal" : "After meal" }}
                        </td>
                        <td
                          class="min-w-[100px] py-5 border-t border-gray-200 text-sm text-DavyGrey"
                        >
                          {{ dateFormatter(record.check_time) }}
                        </td>
                        <td
                          class="min-w-[100px] py-5 border-t border-gray-200 text-sm text-DavyGrey"
                        >
                          {{ timeFormatter(record.check_time) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- v-if="latestPatientRecord?.length > 6" -->
                  <div
                    class="flex items-center space-x-3"
                    v-if="getPatientsList?.length > 6"
                  >
                    <div class="flex flex-row space-x-1.5 font-light text-xs">
                      <BaseButton
                        @click="goToPage(page - 1)"
                        :disabled="page === 1"
                        :class="{ 'cursor-not-allowed': page === 1 }"
                        class="flex justify-center items-center w-10 h-10 border rounded"
                      >
                        <DirectionalLeftIcon width="17" height="14" />
                      </BaseButton>
                      <BaseButton
                        v-for="pageNumber in totalPages"
                        :key="pageNumber"
                        :class="[
                          'w-10 h-10 border rounded text-BalticSea',
                          {
                            'bg-ResolutionBlue text-white': page === pageNumber,
                          },
                        ]"
                        @click="goToPage(pageNumber)"
                      >
                        {{ pageNumber }}
                      </BaseButton>
                      <BaseButton
                        @click="goToPage(page + 1)"
                        :disabled="page === totalPages"
                        class="flex justify-center items-center w-10 h-10 border rounded"
                        :class="{ 'cursor-not-allowed': page === totalPages }"
                      >
                        <DirectionalRightIcon width="8" height="8" />
                      </BaseButton>
                    </div>
                    <div class="text-xs text-IronsideGrey">
                      <span>of </span>
                      <span>{{ totalPages }} pages</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="flex flex-col justify-center items-center py-16 md:py-24 bg-WhiteLilac space-y-5 rounded-lg"
            >
              <div
                class="w-fit bg-BlueChalk p-6 rounded-full flex justify-center items-center"
              >
                <DocumentIcon class="" />
              </div>
              <p class="text-DavyGrey">No vitals captured yet</p>
              <!-- <button
                  class="w-fit rounded-3xl bg-ResolutionBlue text-white px-16 py-3.5 text-sm font-semibold"
                >
                  Add Device
                </button> -->
            </div>
          </div>
        </div>
        <!-- <div>
            <div v-if="isChartActive">Chart Content</div>
            <div v-else>Table Content</div>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import DocumentIcon from "@/components/icons/DocumentIcon.vue";
import DirectionalLeftIcon from "@/components/icons/DirectionalLeftIcon.vue";
import DirectionalRightIcon from "@/components/icons/DirectionalRightIcon.vue";
import ChartIcon from "@/components/icons/ChartIcon.vue";
import TableIcon from "@/components/icons/TableIcon.vue";
import { dateFormatter } from "@/utils/dateFormatter";
import { timeFormatter } from "@/utils/dateFormatter";
import BaseButton from "@/components/main/ui/BaseButton.vue"

const route = useRoute();
const { "user/fetchPatientsList": fetchPatientsList } = mapActions();
const { "user/getPatientsList": getPatientsList } = mapGetters();

// access the patient name from url
const patientFullName = computed(() => {
  console.log("params:", route.params.full_name);
  return route.params.full_name;
});

const hypoglycaemic = ref(70);
const normal = ref(100);
const average_high = ref(125);
const high = ref(126);
const page = ref(1);
const itemsPerPage = ref(6);
const isChartActive = ref(false); // Default to patient
const isTableActive = ref(true);
const patient = ref(
  getPatientsList?.value?.patients.find(
    (patient) => patient.person.full_name === patientFullName.value
  ) || {}
);
const latestPatientRecord = ref([]);
const totalPages = ref(
  Math.ceil(
    latestPatientRecord.value &&
      latestPatientRecord?.value &&
      latestPatientRecord?.value.length / itemsPerPage.value
  )
);

const getPaginatedRecords = () => {
  const start = (page.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return latestPatientRecord?.value
    ? latestPatientRecord.value.slice(start, end)
    : {};
};

const paginatedPatientRecords = ref(getPaginatedRecords());

console.log("px:", paginatedPatientRecords.value);

const toggleSwitch = (role) => {
  if (role === "chart") {
    isChartActive.value = true;
    isTableActive.value = false;
  } else if (role === "table") {
    isChartActive.value = false;
    isTableActive.value = true;
  }
};

// const patient = computed(() => {
//   // console.log("insid", getPatientsList.value);
//   const patientsList = getPatientsList?.value?.patients;
//   console.log("pl:", patientsList);
//   const foundPatient = patientsList.find(
//     (patient) => patient.person.full_name === patientFullName.value
//   );
//   // console.log("foundPatient", foundPatient);
//   return foundPatient || {};
// });

// sort by most recent patient record
// const latestPatientRecord = computed(() => {
//   if (patient.value.glucose_records) {
//     return [...patient.value.glucose_records].sort(
//       (a, b) => new Date(b.check_time) - new Date(a.check_time)
//     );
//   } else {
//     return [];
//   }
// });

// total number of page
// const totalPages = computed(() => {
//   console.log("itemsPerPage:", itemsPerPage.value);
//   return Math.ceil(
//     latestPatientRecord &&
//       latestPatientRecord?.value &&
//       latestPatientRecord?.value.length / itemsPerPage.value
//   );
// });

// switch to previous or next page
const goToPage = (pageNumber) => {
  // console.log("page:", pageNumber);
  if (pageNumber >= 1 && pageNumber <= totalPages.value) {
    page.value = pageNumber;
  }
};

// list of patient record per page
// const paginatedPatientRecords = computed(() => {
//   const start = (page.value - 1) * itemsPerPage.value;
//   const end = start + itemsPerPage.value;
//   return latestPatientRecord?.value
//     ? latestPatientRecord.value.slice(start, end)
//     : {};
// });

onMounted(async () => {
  await fetchPatientsList();

  const patientsList = getPatientsList?.value?.patients;
  console.log("pl:", patientsList);

  const foundPatient = patientsList.find(
    (patient) => patient.person.full_name === patientFullName.value
  );
  // console.log("foundPatient", foundPatient);
  patient.value = foundPatient || {};

  if (patient.value.glucose_records) {
    latestPatientRecord.value = [...patient.value.glucose_records].sort(
      (a, b) => new Date(b.check_time) - new Date(a.check_time)
    );
  } else {
    latestPatientRecord.value = [];
  }

  totalPages.value = Math.ceil(
    latestPatientRecord.value &&
      latestPatientRecord?.value &&
      latestPatientRecord?.value.length / itemsPerPage.value
  );

  const start = (page.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  paginatedPatientRecords.value = latestPatientRecord?.value
    ? latestPatientRecord.value.slice(start, end)
    : {};

  console.log("getPatientsList kk", getPatientsList?.value.length);
  console.log("patient in vital value", patient?.value);
  console.log("latestPatientRecord kk", latestPatientRecord?.value.length);
});
</script>

<style scoped>
.toggle-switch {
  @apply w-fit h-fit bg-BlueChalk rounded-full flex justify-between items-center p-1 cursor-pointer transition duration-300;
}

.switch-state {
  @apply w-fit px-2 py-1 space-x-1 rounded-full flex justify-center items-center font-medium text-[10px] md:text-xs text-Gravel transition duration-500;
}

.table {
  @apply bg-ResolutionBlue text-white font-semibold;
}

.chart {
  @apply bg-TealishGreen text-ResolutionBlue font-semibold;
}
</style>
