<template>
  <div class="flex flex-col items-start md:flex-row md:space-x-16 px-6">
    <div class="w-full md:w-[15%]">
      <div class="flex flex-col items-center form-group">
        <div class="w-32 h-32 rounded-full border">
          <template v-if="preview">
            <img :src="preview" class="w-32 h-32 rounded-full object-cover" />
          </template>
          <template v-else>
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              class="w-32 h-32 rounded-full object-cover"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="w-full md:w-[50%]">
      <div>
        <div
          class="space-y-2.5"
          :class="{
            'border-b border-b-BlueChalk mb-4': openResidentialAddress !== true,
            'mb-12': openResidentialAddress === true,
            'transition-transform duration-700': true,
          }"
        >
          <div
            class="flex items-center justify-between space-x-4 cursor-pointer"
            @click="openResidentialAddress = !openResidentialAddress"
          >
            <h4 class="text-sm md:text-base font-bold text-DarkJungleGreen">
              General Info
            </h4>

            <div>
              <span
                ><img
                  :src="arrowUpIcon"
                  :class="{
                    'rotate-180': openResidentialAddress !== true,
                    'transition-transform': true,
                    'duration-700': true,
                  }"
                  alt=""
              /></span>
            </div>
          </div>
          <div
            :class="[
              {
                'max-h-0': true,
                'overflow-hidden': true,
                'transition-max-height': true,
                'duration-1000': true,
                'max-h-[500px]': openResidentialAddress === true,
              },
            ]"
          >
            <div class="space-y-6">
              <p class="text-MistBlue font-bold text-xs md:text-sm">
                Primary Care Physician
              </p>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div
                  class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                    Primary Care Physician's Name
                  </p>
                  <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                    {{
                      patient?.person?.primary_care_physician_name
                        ? patient?.person?.primary_care_physician_name
                        : "--"
                    }}
                  </p>
                </div>
                <div
                  class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                    Hospital
                  </p>
                  <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                    {{
                      patient?.person?.primary_care_physician_hospital
                        ? patient?.person?.primary_care_physician_hospital
                        : "--"
                    }}
                  </p>
                </div>
                <div
                  class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                    Phone Number
                  </p>
                  <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                    {{
                      patient?.person?.primary_care_physician_phone
                        ? patient?.person?.primary_care_physician_phone
                        : "--"
                    }}
                  </p>
                </div>
                <div
                  class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Email</p>
                  <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                    {{ patient?.person?.primary_care_physician_email }}
                  </p>
                </div>
              </div>
              <div class="space-y-6">
                <h4 class="text-MistBlue font-bold text-xs md:text-sm">
                  Preferred Hospital/ Frequently Visited Hospital
                </h4>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div
                    class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
                  >
                    <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                      Hospital Name
                    </p>
                    <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                      {{
                        patient?.person?.preferred_hospital
                          ? patient?.person?.preferred_hospital
                          : "--"
                      }}
                    </p>
                  </div>
                  <!-- <div
                    class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
                  >
                    <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                      Hospital Name
                    </p>
                    <input
                      type="text"
                      class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0"
                      :placeholder="
                        patient?.person?.preferred_hospital
                          ? patient?.person?.preferred_hospital
                          : '--'
                      "
                    />
                  </div> -->
                  <!-- <div
                    class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
                  >
                    <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                      Street
                    </p>
                    <input
                      type="text"
                      class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0"
                      :placeholder="
                        patient?.person?.preferred_hospital_street
                          ? patient?.person?.preferred_hospital_street
                          : '---'
                      "
                    />
                  </div>
                  <div
                    class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
                  >
                    <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                      City
                    </p>
                    <input
                      type="text"
                      class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0"
                      :placeholder="
                        patient?.person?.preferred_hospital_city
                          ? patient?.person?.preferred_hospital_city
                          : '--'
                      "
                    />
                  </div>

                  <div
                    class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
                  >
                    <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                      State
                    </p>
                    <input
                      type="text"
                      class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0"
                      :placeholder="
                        patient?.person?.preferred_hospital_state
                          ? patient?.person?.preferred_hospital_state
                          : '--'
                      "
                    />
                  </div>
                  <div
                    class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
                  >
                    <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                      ZIP Code
                    </p>
                    <input
                      type="text"
                      class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0"
                      :placeholder="
                        patient?.person?.preferred_hospital_zip_code
                          ? patient?.person?.preferred_hospital_zip_code
                          : '--'
                      "
                    />
                  </div>
                  <div
                    class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
                  >
                    <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                      Phone Number
                    </p>
                    <input
                      type="number"
                      class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0"
                      :placeholder="
                        patient?.person?.preferred_hospital_phone
                          ? patient?.person?.preferred_hospital_phone
                          : '--'
                      "
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openMedicalHistory !== true,
          'mb-12': openMedicalHistory === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openMedicalHistory = !openMedicalHistory"
        >
          <h4 class="text-sm md:text-base font-bold text-DarkJungleGreen">
            Medical History
          </h4>
          <div>
            <span
              ><img
                :src="arrowUpIcon"
                :class="{
                  'rotate-180': openMedicalHistory !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[5000px]': openMedicalHistory === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Height</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.height ? patient?.person?.height : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Weight</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.weight ? patient?.person?.weight : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Blood group
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.blood_group
                    ? patient?.person?.blood_group
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Diabetes type
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.diabetes_type
                    ? patient?.person?.diabetes_type
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Date of Diabetes Diagnosis
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.date_of_diagnosis
                    ? patient?.person?.date_of_diagnosis
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Insulin Dosage (if applicable)
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.insulin_dosage
                    ? patient?.person?.insulin_dosage
                    : "--"
                }}
              </p>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Chronic Diseases
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 1
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_1
                      ? patient?.person?.chronic_disease_1
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Date of Diagnosis
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.date_of_chronic_1_diagnosis
                      ? patient?.person?.date_of_chronic_1_diagnosis
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="col-span-2 flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_1_medication
                      ? patient?.person?.chronic_disease_1_medication
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 2
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_2
                      ? patient?.person?.chronic_disease_2
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Date of Diagnosis
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.date_of_chronic_2_diagnosis
                      ? patient?.person?.date_of_chronic_2_diagnosis
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="col-span-2 flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_2_medication
                      ? patient?.person?.chronic_disease_2_medication
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 3
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_3
                      ? patient?.person?.chronic_disease_3
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Date of Diagnosis
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.date_of_chronic_3_diagnosis
                      ? patient?.person?.date_of_chronic_3_diagnosis
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="col-span-2 flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_3_medication
                      ? patient?.person?.chronic_disease_3_medication
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 4
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_4
                      ? patient?.person?.chronic_disease_4
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Date of Diagnosis
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.date_of_chronic_4_diagnosis
                      ? patient?.person?.date_of_chronic_4_diagnosis
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="col-span-2 flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.chronic_disease_4_medication
                      ? patient?.person?.chronic_disease_4_medication
                      : "--"
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Surgical History
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Surgery 1
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.surgery_1
                      ? patient?.person?.surgery_1
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Date of Surgery
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.date_of_surgery_2
                      ? patient?.person?.date_of_surgery_2
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="col-span-2 flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Reason for Surgery
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.reason_for_surgery_1
                      ? patient?.person?.reason_for_surgery_1
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Surgery 2
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.surgery_2
                      ? patient?.person?.surgery_2
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Date of Surgery
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.date_of_surgery_2
                      ? patient?.person?.date_of_surgery_2
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="col-span-2 flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Reason for Surgery
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.reason_for_surgery_2
                      ? patient?.person?.reason_for_surgery_2
                      : "--"
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Allergies
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 1
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.allergy_1
                      ? patient?.person?.allergy_1
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 2
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.allergy_2
                      ? patient?.person?.allergy_2
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 3
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.allergy_3
                      ? patient?.person?.allergy_3
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 4
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.allergy_4
                      ? patient?.person?.allergy_4
                      : "--"
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Other Medications (excluding diabetic drugs)
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 1
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.medication_1
                      ? patient?.person?.medication_1
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.dosage_1 ? patient?.person?.dosage_1 : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 2
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.medication_2
                      ? patient?.person?.medication_2
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.dosage_2 ? patient?.person?.dosage_2 : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 3
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.medication_3
                      ? patient?.person?.medication_3
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.dosage_3 ? patient?.person?.dosage_3 : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 4
                </p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.medication_4
                      ? patient?.person?.medication_4
                      : "--"
                  }}
                </p>
              </div>
              <div
                class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                  {{
                    patient?.person?.dosage_4 ? patient?.person?.dosage_4 : "--"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import arrowUpIcon from "@/assets/icons/arrow-up-iocn.svg"

const props = defineProps(["patient"]);
const openResidentialAddress = ref(true);
const openMedicalHistory = ref(true);
</script>
