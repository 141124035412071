<template>
  <div class="border rounded-[20px] px-10 py-6 space-y-4">
    <div class="space-y-2">
      <p class="text-DarkJungle font-bold md:text-xl">
        Care for your loved one
      </p>
      <p class="text-GreyChateau text-sm">
        Book appointments and assist with glucose testing for your loved ones.
      </p>
    </div>

    <div class="bg-GhostWhite border border-GreenWhite px-6 py-4 rounded-xl">
      <h5 class="font-semibold text-lg mb-2">
        Assist with blood glucose testing
      </h5>
      <p class="text-MistBlue text-xs md:text-sm md:max-w-[22rem] mb-4">
        Help monitor their glucose levels when they're unwell
      </p>
      <div>
        <div class="flex items-center space-x-3 w-full my-2">
          <BaseButton
            class="custom-button font-medium bg-ResolutionBlue text-sm md:text-[14px] rounded-3xl text-white py-2 px-10 duration-50 w-full"
            @click="startNewTest"
          >
            Start new test
          </BaseButton>
          <BaseButton
            class="border border-ResolutionBlue custom-button font-medium text-sm md:text-[14px] rounded-3xl text-ResolutionBlue py-2 px-10 duration-500 w-full"
            @click="openManualTest"
          >
            Manual Entry
          </BaseButton>
        </div>
      </div>
    </div>

    <div
      class="w-full bg-GhostWhite border border-GreenWhite px-6 py-4 rounded-xl"
    >
      <h5 class="font-semibold text-lg mb-2">Schedule an appointment</h5>
      <p class="text-MistBlue text-xs md:text-sm md:max-w-[22rem] mb-4">
        Easily schedule medical visits for your loved ones
      </p>
      <div>
        <div class="flex items-center space-x-3 w-[80%] my-2">
          <BaseButton
            class="custom-button font-medium bg-ResolutionBlue text-sm md:text-[14px] rounded-3xl text-white py-2 px-10 duration-50 w-[50%]"
            @click="openLinkedAppointmentModal"
          >
            Book appointment
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, watch, ref } from "vue";
import BaseButton from "@/components/main/ui/BaseButton.vue"

const props = defineProps({
  patientDetails: {
    type: Object,
    required: true,
  },
});

const patientName = ref("");
const patientId = ref("");

watch(
  () => props.patientDetails,
  (newVal) => {
    if (newVal && typeof newVal.full_name === "string" && newVal.id) {
      patientName.value = newVal.full_name;
      patientId.value = newVal.id;
    } 
  },
  { immediate: true }
);

const openLinkedAccountTakeTestModal = inject("openLinkedAccountTakeTestModal");
const openLinkedAccountManualTestModal = inject(
  "openLinkedAccountManualTestModal"
);

// Function to open the modal when "Start new test" is clicked
const startNewTest = () => {
  if (patientName.value && patientId.value) {
    openLinkedAccountTakeTestModal(patientName.value, patientId.value);
  } 
};

// Open manual test modal
const openManualTest = () => {
  if (patientId.value && patientName.value) {
    openLinkedAccountManualTestModal(patientId.value, patientName.value);
  } 
};

const openLinkedAppointmentModal = inject("openLinkedAppointmentModal");
</script>
