<template>
  <form @submit.prevent="handleSubmit" class="mt-36">
    <div class="text-center mb-3">
      <h1 class="text-black text-2xl font-bold mb-2">Forgot password</h1>
      <p class="text-OlsoGrey text-base max-w-[344px]">
        Enter the email address you registered with and we will send you a link
        to create a new password.
      </p>
    </div>

    <div class="mb-7">
      <label for="email" class="text-sm text-BluishGrey">Email</label>
      <Input
        type="text"
        name="email"
        id="email"
        autocomplete="true"
        class="w-full py-1 focus:outline-none border-b border-BlueChalk text-DarkJungle text-sm font-bold focus:border-ResolutionBlue focus:ring-2"
        v-model="email"
      />
    </div>

    <div class="mb-7">
      <button
        class="w-full custom-button font-bold bg-ResolutionBlue rounded-[44px] py-3 px-1 md:py-4 md:px-2 duration-500 text-sm text-white"
        :class="!isLoginDisabled && 'hover:bg-DodgerBlue'"
        :disabled="isLoginDisabled || isLoading"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else>Send link</span>
      </button>
    </div>

    <div class="text-center">
      <p class="text-MountainMist text-xs font-semibold">
        Remember password?
        <router-link to="/auth/sign-in" class="text-ResolutionBlue">
          Sign in now</router-link
        >
      </p>
    </div>
  </form>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import Input from "@/components/main/ui/Input.vue";

    const router = useRouter();
    const { "auth/sendResetToken": sendResetToken } = mapActions();
    const email = ref("");
    const isLoading = ref(false);
    const isLoginDisabled = computed(() => {
      return !(!!email.value && /^[^@]+@\w+(\.\w+)+\w$/.test(email.value));
    });
    const handleSubmit = async () => {
      isLoading.value = true;
      try {
        await sendResetToken({ email: email.value });
        isLoading.value = false;
        router.push({
          name: "reset-email-sent",
          query: { email: email.value.replace("@", "%") },
        });
        email.value = "";
      } catch (error) {
        // console.log(error.response.data.message);
        isLoading.value = false;
        push.error(error.response.data.message);
      }
    };
</script>
