<template>
  <section
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="bg-white w-full xl:mx-10 rounded-lg shadow-xl p-4 md:p-10">
      <h1 class="text-ResolutionBlue text-2xl font-bold mb-6">
        Diagnostic Imaging Hub
      </h1>

      <div
        class="w-full h-full border border-BlueChalk rounded-[18px] py-6 px-10"
      >
        <div class="flex justify-between items-center mb-6 flex-wrap gap-y-2">
          <h3 class="text-BalticSea text-base font-bold">Documents</h3>

          <div class="flex items-center gap-3 flex-wrap">
            <BaseButton
              v-if="!isAdmin"
              class="bg-ResolutionBlue text-white font-semibold rounded-[40px] py-1.5 px-3 flex justify-between items-center gap-1"
              :class="isLoading && 'opacity-50'"
              :disabled="isLoading"
              @click="openReportAndStatisticsModal"
            >
              <img
                :src="circleWhiteIcon"
                alt="add circle icon"
              />
              <span class="text-xs">Upload</span>
            </BaseButton>
          </div>
        </div>

        <!-- Loading Indicator -->
        <div
          v-if="isFetching"
          class="w-full mx-auto h-64 flex justify-center items-center"
        >
          <div class="w-5 h-5">
            <SpinnerIcon />
          </div>
        </div>

        <!-- No Data Message -->
        <NoDataMessage
          v-else-if="isSuccess && documentsData?.total_items === 0"
          text="You have no health reports in your Document Hub."
        />

        <ErrorMessage v-else-if="isError" />

        <!-- Table with Documents -->
        <div v-else-if="isSuccess" class="custom-scrollbar overflow-auto">
          <Table :columns="columns" :tableData="paginatedDocument" />
        </div>

        <Teleport to="body">
          <ReportActionBox
            v-if="actionBoxVisible"
            :pdf="
              paginatedDocument.find((doc) => doc.id === actionBoxVisible)
                ?.documents
            "
            :style="{
              top: `${actionBoxPosition.top}px`,
              left: `${actionBoxPosition.left}px`,
              position: 'absolute',
              zIndex: 1000,
            }"
            @delete="handleDelete(actionBoxVisible)"
            @close="toggleActionBox"
          />
        </Teleport>

        <!-- Pagination Controls -->
        <div class="flex items-center py-4">
          <BaseButton
            class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
            :disabled="currentPage === 1"
            @click="currentPage--"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </BaseButton>

          <div class="flex space-x-2 mx-2">
            <BaseButton
              v-for="page in visiblePages"
              :key="page"
              @click="currentPage = page"
              :class="[
                'w-[38px] h-[38px] flex justify-center items-center border rounded-[4px]',
                currentPage === page
                  ? 'bg-ResolutionBlue text-white'
                  : 'bg-gray-100 text-black',
              ]"
            >
              {{ page }}
            </BaseButton>
          </div>
          <BaseButton
            class="flex justify-center items-center w-[38px] h-[38px] bg-gray-100 border rounded-[4px] disabled:opacity-50"
            :disabled="currentPage === totalPages"
            @click="currentPage++"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </BaseButton>

          <span class="text-gray-500 ml-2">of {{ totalPages }} pages</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { inject, ref, computed, watch, h } from "vue";
import { useQuery, useMutation } from "@tanstack/vue-query";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useStore } from "vuex";
import { push } from "notivue";
import { dateFormatter } from "@/utils/dateFormatter";
import debounce from "lodash/debounce";
import ReportActionBox from "@/components/main/patient/reportAndStatistics/ReportActionBox.vue";
import optionButton from "@/assets/icons/option-button.svg";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import circleWhiteIcon from "@/assets/icons/add_circle_white.svg"

const {
  "user/getAllDocumentUploads": getAllDocumentUploads,
  "user/deleteDocument": deleteDocument,
} = mapActions();

const openReportAndStatisticsModal = inject("openReportAndStatisticsModal");
const openReportNoteModal = inject("openReportNoteModal");
const reportNote = inject("reportNote");

const isAdmin = computed(() => useStore().getters["auth/getIsAdmin"]);

const setCurrentNote = (val) => {
  reportNote.value = val;
  openReportNoteModal();
};

const closeActionBox = () => {
  currentIndex.value = null;
};

const currentPage = ref(1);
const itemsPerPage = 10;
const actionBoxVisible = ref(null);
const actionBoxPosition = ref({ top: 0, left: 0 });

// Toggle the visibility of the action box
const toggleActionBox = (event = null, recordId = null) => {
  if (event) {
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    actionBoxPosition.value = {
      top: top + height + window.scrollY,
      left: left + window.scrollX,
    };
  }
  actionBoxVisible.value =
    actionBoxVisible.value === recordId ? null : recordId;
};

// Handle delete action
const handleDelete = (documentId) => {
  console.log("Deleting document with ID:", documentId);
  deleteReport(documentId);
};

const columns = ref([
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
    render: (record) => h("span", null, dateFormatter(record.created_at)),
  },
  {
    title: "Type",
    dataIndex: "procedure",
    key: "procedure",
  },
  {
    title: "Notes",
    dataIndex: "body_part",
    key: "body_part",
  },
  {
    title: "Actions",
    key: "actions",
    render: (record) =>
      h("div", { class: "relative" }, [
        h("img", {
          src: optionButton,
          class: "cursor-pointer",
          onClick: (event) => toggleActionBox(event, record.id),
        }),
      ]),
  },
]);

// Fetch documents using vue-query and Vuex action
const {
  data: documentsData,
  isFetching,
  isSuccess,
  isError,
  refetch,
} = useQuery({
  queryKey: ["allDocuments", currentPage],
  queryFn: () => getAllDocumentUploads({ page: currentPage.value }),
  keepPreviousData: true,
  onSuccess: (data) => {
    console.log("Successfully fetched documents:", data);
  },
  onError: (error) => {
    console.error("Error fetching documents:", error);
  },
});

// Delete document using useMutation
const { mutate: deleteReport, isLoading } = useMutation({
  mutationFn: (documentId) => deleteDocument(documentId),
  onSuccess: () => {
    push.success("Document deleted successfully.");
    refetch(); // Refetch data after deletion
  },
  onError: () => {
    push.error("Error deleting document.");
  },
});

// Pagination controls
const visiblePages = computed(() => {
  const maxVisible = 5;
  let startPage = Math.max(1, currentPage.value - Math.floor(maxVisible / 2));
  let endPage = Math.min(totalPages.value, startPage + maxVisible - 1);
  if (endPage - startPage + 1 < maxVisible && totalPages.value >= maxVisible) {
    startPage = Math.max(1, endPage - maxVisible + 1);
  }
  return Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );
});

const debouncedFetchDocuments = debounce(() => refetch(), 500);
watch(currentPage, debouncedFetchDocuments);

// Computed property for paginated document data
const paginatedDocument = computed(() => {
  const data = documentsData?.value?.document_uploads || [];
  console.log("Paginated Document Data:", data);
  return data;
});

// Computed property for total pages
const totalPages = computed(() => {
  const totalItems = documentsData.value?.total_items || 0;
  const pages = Math.ceil(totalItems / itemsPerPage);
  console.log("Total Items:", totalItems);
  console.log("Calculated Total Pages:", pages);
  return pages;
});

// Watch for changes in currentPage and log it
watch(currentPage, (newPage) => {
  console.log("Current Page:", newPage);
  refetch();
});
</script>
