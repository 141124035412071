<template>
  <div class="w-full">
    <div class="border-[0.1px] border-LightGrey rounded-lg px-4 py-8 h-[23rem]">
      <div class="h-[10%] flex items-center justify-between mb-4">
        <div
          class="flex flex-row items-center rounded-3xl bg-BlueChalk px-1.5 py-1"
        >
          <span
            @click="toggleTabs(1)"
            class="cursor-pointer text-sm flex items-center space-x-2"
            :class="{
              ' px-4 py-1.5': openTab !== 1,
              'rounded-2xl px-4 py-1.5  bg-ResolutionBlue': openTab === 1,
            }"
          >
            <span
              class=""
              :class="{
                'text-PaleSky': openTab !== 1,
                'font-bold text-white': openTab === 1,
              }"
              >Received</span
            >
            <span
              :class="{
                'text-black/70 bg-black/10': openTab !== 1,
                'bg-white text-ResolutionBlue': openTab === 1,
              }"
              class="text-xs px-[5px] py-[1px] rounded-full"
              >{{ data?.total_pending_connections_received }}</span
            >
          </span>
          <span
            @click="toggleTabs(2)"
            class="cursor-pointer text-sm flex items-center space-x-2"
            :class="{
              'text-PaleSky px-6 py-1.5': openTab !== 2,
              'font-bold rounded-2xl px-6 py-1.5 text-white bg-ResolutionBlue':
                openTab === 2,
            }"
          >
            <span>Sent</span>
            <span
              :class="{
                'text-black/70 bg-black/10': openTab !== 2,
                'bg-white text-ResolutionBlue': openTab === 2,
              }"
              class="text-xs px-[5px] py-[1px] rounded-full"
              >{{ data?.total_pending_connections_sent }}</span
            >
          </span>
        </div>
      </div>

      <div class="h-[90%]">
        <div class="overflow-x-auto h-full py-6">
          <div :class="{ hidden: openTab !== 1, block: openTab === 1 }">
            <div
              v-if="
                data?.pending_connections_received === null ||
                data?.pending_connections_received < 1
              "
            >
              No available received request
            </div>
            <div v-else-if="data?.pending_connections_received">
              <div
                class="mb-4"
                v-for="(
                  connections_received, index
                ) in data?.pending_connections_received"
                :key="index"
              >
                <RequestsReceived
                  :connections_received="connections_received"
                />
              </div>
            </div>
          </div>

          <div :class="{ hidden: openTab !== 2, block: openTab === 2 }">
            <div
              v-if="
                data?.pending_connections_sent === null ||
                data?.pending_connections_sent < 1
              "
            >
              No available sent request
            </div>
            <div v-else-if="data?.pending_connections_sent">
              <div
                class="mb-4"
                v-for="(
                  connections_sent, index
                ) in data?.pending_connections_sent"
                :key="index"
              >
                <RequestsSent :connections_sent="connections_sent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import RequestsReceived from "./RequestsReceived.vue";
import RequestsSent from "./RequestsSent.vue";

const { "accountConnection/fetchPatientRequests": fetchPatientRequests } =
  mapActions();

const openTab = ref(1);

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};

const { data } = useQuery({
  queryKey: ["linked-accounts"],
  queryFn: () => fetchPatientRequests(),
});
</script>
