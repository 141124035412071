<template>
    <button
      :class="computedClasses"
      :type="type"
      :disabled="disabled"
      @click="handleClick"
    >
      <slot />
    </button>
  </template>
  
  <script setup>
  import { computed } from "vue";
  
  const props = defineProps({
    type: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  });
  
  const emit = defineEmits(["click"]);
  
  const computedClasses = computed(() => {

    return ` ${props.classes}`;
  });
  
  function handleClick(event) {
    if (!props.disabled) {
      emit("click", event);
    }
  }
  </script>
  
  <style scoped></style>
  