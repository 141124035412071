<template>
  <tbody class="text-xs md:text-sm">
    <Tr
      :trStyles="trStyles"
      v-for="data in tableData"
      :key="data.id ?? generateRandomId()"
    >
      <Td
        :tdStyles="tdStyles"
        v-for="(column, index) in columns"
        :key="index"
        :column="column"
        :data="data"
      />
    </Tr>
  </tbody>
</template>

<script setup>
import Tr from "./Tr.vue";
import Td from "./Td.vue";

const generateRandomId = () => {
  return "id-" + Math.random().toString(36).substring(2, 11);
};

defineProps({
  columns: {
    type: Array,
    required: true,
  },
  tableData: {
    type: Array,
    required: true,
  },
  trStyles: {
    type: String,
  },
  tdStyles: {
    type: String,
  },
});
</script>
