<template>
    <input
      :type="type"
      :value="modelValue"
      :class="computedClasses"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="handleInput"
    />
  </template>
  
  <script setup>
  import { computed } from "vue";
  
  const props = defineProps({
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  });
  
  const emit = defineEmits(["update:modelValue"]);
  
  const computedClasses = computed(() => {
    const baseClasses = "focus:outline-none transition-all";
    return `${baseClasses} ${props.classes}`;
  });
  
  function handleInput(event) {
    emit("update:modelValue", event.target.value);
  }
  </script>
  
  <style scoped></style>
  