import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

// Vue Query
import { VueQueryPlugin } from "@tanstack/vue-query";

// Notivue
import { createNotivue } from "notivue";

const notivue = createNotivue({
  position: "top-right",
  limit: 4,
  enqueue: true,
  avoidDuplicates: true,
  notifications: {
    global: {
      duration: 2500,
    },
  },
});

// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueQueryPlugin) 
  .use(vuetify)
  .use(notivue)
  .mount("#app");
