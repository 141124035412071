<template>
  <ModalWrapper>
    <div class="bg-white w-full max-w-[569px] p-8 rounded-[48px]">
      <div
        @click="closeFileUploadSuccessfulModal"
        class="flex justify-end cursor-pointer"
      >
        <span
          class="w-4 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-DarkJungleGreen before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-DarkJungleGreen after:left-0 after:top-0 after:rounded-sm after:rotate-45"
        ></span>
      </div>
      <div class="mb-9">
        <img
          :src="activeIcon"
          alt="active icon"
          class="mx-auto"
        />
      </div>

      <div class="text-center mb-7">
        <h3 class="text-DarkJungle text-2xl font-bold mb-2">Success</h3>
        <p class="text-MistBlue text-sm">
          Your report has been uploaded Successfully
        </p>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { inject } from "vue";
import ModalWrapper from "@/components/main/admin/testCenter/modals/ModalWrapper.vue";
import activeIcon from "@/assets/icons/active-icon.svg"

    const closeFileUploadSuccessfulModal = inject(
      "closeFileUploadSuccessfulModal"
    );
</script>
