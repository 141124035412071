<template>
  <div class="w-full md:w-[20%]">
    <div class="flex flex-col items-center form-group">
      <div class="w-32 h-32 rounded-full border relative">
        <img
          :src="profilePicture"
          class="w-full h-full rounded-full object-contain"
          :class="isLoading ? 'opacity-75' : 'opacity-100'"
        />

        <div
          v-show="isLoading"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <LoadingSpinner />
        </div>
      </div>

      <form
        @submit.prevent="handleSubmit"
        class="flex items-center justify-center w-full"
      >
        <label
          for="profilePicture"
          class="flex flex-col items-center justify-center w-full cursor-pointer"
        >
          <div class="flex flex-row items-center justify-center space-x-2 py-4">
            <ReplaceProfileIcon />
            <span class="text-xs text-ResolutionBlue">{{
              isLoading ? "Uploading..." : "Upload new picture"
            }}</span>
          </div>
          <Input
            id="profilePicture"
            type="file"
            class="hidden"
            accept="image/*"
            @input="previewImage"
            :disabled="isLoading"
          />
        </label>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { mapActions, mapGetters, mapMutations } from "@/hooks/mapStore";
import { push } from "notivue";
import ReplaceProfileIcon from "@/components/icons/ReplaceProfileIcon.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Input from "@/components/main/ui/Input.vue"

const {
  "user/setProfilePictureAction": setProfilePicture,
  "user/fetchPatientProfile": fetchPatientProfile,
} = mapActions();

const {
  "user/getPatientProfile": getPatientProfile,
  "user/getUserProfilePicture": profilePicture,
} = mapGetters();

const { "user/setProfilePicture": updateProfilePicture } = mapMutations();

const image = ref(null);
const isLoading = ref(false);

const previewImage = async (event) => {
  const input = event.target;
  const maxSizeInMB = 1;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  if (input.files && input.files[0]) {
    const file = input.files[0];

    if (file.size > maxSizeInBytes) {
      push.error(
        `The file size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`
      );
      return;
    }

    image.value = file;
    handleSubmit();
  }
};

const handleSubmit = async () => {
  try {
    isLoading.value = true;
    const formData = new FormData();
    formData.append("file", image.value);

    await setProfilePicture({
      id: getPatientProfile.value.person.id,
      formData,
    });

    push.success("Profile picture updated!");

    updateProfilePicture(URL.createObjectURL(image.value));
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const errorMsg = error.response.data.message;
      push.error(
        errorMsg.includes("Invalid file format")
          ? "Invalid file format. Please upload a JPEG or PNG file."
          : errorMsg
      );
    } else {
      push.error("The file size exceeds 1MB. Please upload a smaller file.");
    }
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await fetchPatientProfile();
});
</script>
