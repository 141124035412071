import axios from "@/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // add new blog post
  async addBlogPost(_, formData) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await axios.post("/blog/create", formData, config);
    return response.data;
  },

  // fetch all the blog posts
  async fetchBlogPosts() {
    const response = await axios.get(`/blog/posts`);

    return response.data;
  },

  // update blog post title and author
  async updateBlogPostTitleAndAuthor(_, payload) {
    await axios.put(`/blog/post/update/${payload.id}`, {
      title: payload.title,
      author: payload.author,
    });
  },

  // update blog post body
  async updateBlogPostBody(_, payload) {
    const formData = new FormData();
    formData.append("body", payload.body);
    await axios.patch(`/blog/post/update/body/${payload.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // update blog post image
  async updateBlogPostImage(_, payload) {
    const formData = new FormData();
    if (payload.image) {
      formData.append("image", payload.image);
    }
    await axios.patch(`/blog/post/update/image/${payload.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // delete blog post
  async deleteBlogPost(_, payload) {
    await axios.delete(`/blog/delete/${payload.id}`);
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
