import glucoseIcon from "../../assets/icons/testTypes/glucose_icon.svg";
import heartIcon from "../../assets/icons/testTypes/heart.svg";
import lungsIcon from "../../assets/icons/testTypes/lungs.svg";

export const allTestTypes = [
  {
    name: "Glucose",
    image: glucoseIcon,
    isAvailable: true,
  },
  {
    name: "Heart Rate",
    image: heartIcon,
    isAvailable: false,
  },
  {
    name: "Lungs Capacity",
    image: lungsIcon,
    isAvailable: false,
  },
];

export const testTypes = [
  {
    name: 'Glucose',
    type: 'glucose',
    icon: glucoseIcon
  },
  {
    name: 'Heart Rate',
    type: 'heart_rate',
    icon: heartIcon
  },
  {
    name: 'Blood Pressure',
    type: 'blood_pressure',
    icon: 'bloodPressure' 
  },
  {
    name: 'Weight',
    type: 'weight',
    icon: 'scale' 
  },
  {
    name: 'Temperature',
    type: 'temperature',
    icon: 'thermometer' 
  },
  {
    name: 'ECG',
    type: 'ecg',
    icon: 'pulse'
  }
];
