<template>
  <div class="w-full md:w-[70%]">
    <div>
      <!-- General Info -->
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openResidentialAddress !== true,
          'mb-12': openResidentialAddress === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openResidentialAddress = !openResidentialAddress"
        >
          <h4 class="text-sm md:text-base font-bold text-DarkJungleGreen">
            General Info
          </h4>

          <div>
            <span
              ><img
                :src="arrowUpIcon"
                :class="{
                  'rotate-180': openResidentialAddress !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openResidentialAddress === true,
            },
          ]"
        >
          <div class="space-y-6">
            <p class="text-MistBlue font-bold text-xs md:text-sm">
              Primary Care Physician
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Primary Care Physician's Name
                </p>
                <Input
                  v-model="primary_care_physician_name"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="primary_care_physician_name || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Hospital
                </p>
                <Input
                  v-model="primary_care_physician_hospital"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="primary_care_physician_hospital || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Phone Number
                </p>
                <Input
                  v-model="primary_care_physician_phone"
                  type="number"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="primary_care_physician_phone || '--'"
                />
              </div>

              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Email</p>
                <Input
                  v-model="primary_care_physician_email"
                  type="email"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="primary_care_physician_email || '--'"
                />
              </div>
            </div>
            <div class="mb-4">
              <h4 class="text-MistBlue font-bold text-xs md:text-sm">
                Preferred Hospital/ Frequently Visited Hospital
              </h4>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                    Hospital Name
                  </p>
                  <Input
                    v-model="preferred_hospital"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                    :placeholder="preferred_hospital || '--'"
                  />
                </div>
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                    Country
                  </p>
                  <Input
                    v-model="preferred_hospital_country"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                    :placeholder="preferred_hospital_country || '--'"
                  />
                </div>
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">City</p>
                  <Input
                    v-model="preferred_hospital_city"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                    :placeholder="preferred_hospital_city || '--'"
                  />
                </div>
                <div
                  class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
                >
                  <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">State</p>
                  <Input
                    v-model="preferred_hospital_state"
                    type="text"
                    class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 px-3 py-2 focus:outline-none focus:border focus:border-ResolutionBlue"
                    :placeholder="preferred_hospital_state || '--'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Medical History -->
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openMedicalHistory !== true,
          'mb-12': openMedicalHistory === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openMedicalHistory = !openMedicalHistory"
        >
          <h4 class="text-sm md:text-base font-bold text-DarkJungleGreen">
            Medical History
          </h4>
          <div>
            <span
              ><img
                :src="arrowUpIcon"
                :class="{
                  'rotate-180': openMedicalHistory !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[5000px]': openMedicalHistory === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="w-full px-2">
              <div class="flex flex-col pt-4">
                <label for="height" class="text-[10px] md:text-xs text-OlsoGrey"
                  >Height</label
                >
                <div class="flex items-center gap-2 mt-0.5">
                  <Input
                    name="height"
                    id="height"
                    type="number"
                    v-model="height"
                    class="bg-BlueChalk w-9 rounded py-1 px-[10px] text-DavyGrey text-sm text-center font-bold tabular-nums focus:border-[0.5px] outline-[0.5px] outline-ResolutionBlue"
                  />
                  <div class="flex items-center gap-1">
                    <span class="text-DarkJungleGreen text-xs font-semibold">{{
                      height_units
                    }}</span>
                    <div class="flex flex-col gap-1">
                      <BaseButton @click="incrementHeight">
                        <img
                          :src="numberUpArrowIcon"
                          alt="up arrow"
                          class="w-2.5 h-2"
                        />
                      </BaseButton>
                      <BaseButton @click="decrementHeight">
                        <img
                          :src="numberUpArrowIcon"
                          alt="down arrow"
                          class="w-2.5 h-2 rotate-180"
                        />
                      </BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full px-2">
              <div class="flex flex-col pt-4">
                <label for="weight" class="text-[10px] md:text-xs text-OlsoGrey"
                  >Weight</label
                >
                <div class="flex items-center gap-2 mt-0.5">
                  <Input
                    name="weight"
                    id="weight"
                    type="number"
                    v-model="weight"
                    class="bg-BlueChalk w-9 rounded py-1 px-[10px] text-DavyGrey text-sm text-center font-bold tabular-nums focus:border-[0.5px] outline-[0.5px] outline-ResolutionBlue"
                  />
                  <div class="flex items-center gap-1">
                    <span class="text-DarkJungleGreen text-xs font-semibold">{{
                      weight_units
                    }}</span>
                    <div class="flex flex-col gap-1">
                      <BaseButton @click="incrementWeight">
                        <img
                          :src="numberUpArrowIcon"
                          alt="up arrow"
                          class="w-2.5 h-2"
                        />
                      </BaseButton>
                      <BaseButton @click="decrementWeight">
                        <img
                          :src="numberUpArrowIcon"
                          alt="down arrow"
                          class="w-2.5 h-2 rotate-180"
                        />
                      </BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Blood group
              </p>
              <select
                name=""
                id=""
                class="border-transparent pl-1 pr-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue cursor-pointer"
                v-model="blood_group"
              >
                <option value="" disabled selected>
                  {{ blood_group || "--" }}
                </option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="AB">AB</option>
                <option value="O">O</option>
              </select>
            </div>
            <div
              class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Diabetes type
              </p>
              <select
                name=""
                id=""
                class="border-transparent pl-1 pr-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue cursor-pointer"
                v-model="diabetes_type"
              >
                <option value="" disabled selected>
                  {{ diabetes_type || "--" }}
                </option>
                <option value="Type 1">Type 1</option>
                <option value="Type 2">Type 2</option>
              </select>
            </div>

            <InputField
              label="Date of Diabetes Diagnosis"
              v-model="date_of_diagnosis"
              type="date"
              :placeholder="date_of_diagnosis || '--'"
              :max="currentDate"
            />

            <div
              class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Insulin Dosage (if applicable)
              </p>
              <Input
                v-model="insulin_dosage"
                type="text"
                class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                :placeholder="insulin_dosage || '--'"
              />
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Chronic Diseases
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 1
                </p>
                <Input
                  v-model="chronic_disease_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="chronic_disease_1 || '--'"
                />
              </div>

              <InputField
                label="Date of Diagnosis"
                v-model="date_of_chronic_1_diagnosis"
                type="date"
                :placeholder="date_of_chronic_1_diagnosis || '--'"
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <Input
                  v-model="chronic_disease_1_medication"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="chronic_disease_1_medication || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 2
                </p>
                <Input
                  v-model="chronic_disease_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="chronic_disease_2 || '--'"
                />
              </div>

              <InputField
                label="Date of Diagnosis"
                v-model="date_of_chronic_2_diagnosis"
                type="date"
                :placeholder="date_of_chronic_2_diagnosis || '--'"
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <input
                  v-model="chronic_disease_2_medication"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="chronic_disease_2_medication || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Disease 3
                </p>
                <input
                  v-model="chronic_disease_3"
                  type="number"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="chronic_disease_3 || '--'"
                />
              </div>

              <InputField
                label="Date of Diagnosis"
                v-model="date_of_chronic_3_diagnosis"
                type="date"
                :placeholder="date_of_chronic_3_diagnosis || '--'"
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Current Medications
                </p>
                <Input
                  v-model="chronic_disease_3_medication"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="chronic_disease_3_medication || '--'"
                />
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Surgical History
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Surgery 1
                </p>
                <Input
                  v-model="surgery_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="surgery_1 || '--'"
                />
              </div>

              <InputField
                label="Date of Surgery"
                v-model="date_of_surgery_1"
                type="date"
                :placeholder="date_of_surgery_1 || '--'"
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Reason for Surgery
                </p>
                <Input
                  v-model="reason_for_surgery_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="reason_for_surgery_1 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Surgery 2
                </p>
                <Input
                  v-model="surgery_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="surgery_2 || '--'"
                />
              </div>

              <InputField
                label="Date of Surgery"
                v-model="date_of_surgery_2"
                type="date"
                :placeholder="date_of_surgery_2 || '--'"
                :max="currentDate"
              />

              <div
                class="col-span-2 flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Reason for Surgery
                </p>
                <Input
                  v-model="reason_for_surgery_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="reason_for_surgery_2 || '--'"
                />
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Allergies
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 1
                </p>
                <Input
                  v-model="allergy_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="allergy_1 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 2
                </p>
                <Input
                  v-model="allergy_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="allergy_2 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 3
                </p>
                <Input
                  v-model="allergy_3"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="allergy_3 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Allergy 4
                </p>
                <Input
                  v-model="allergy_4"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="allergy_4 || '--'"
                />
              </div>
            </div>
          </div>
          <div class="mt-6">
            <h4 class="text-MistBlue font-bold text-xs md:text-sm">
              Other Medications (excluding diabetic drugs)
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 1
                </p>
                <Input
                  v-model="medication_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="medication_1 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <Input
                  v-model="dosage_1"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="dosage_1 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 2
                </p>
                <Input
                  v-model="medication_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="medication_2 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <Input
                  v-model="dosage_2"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="dosage_2 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 3
                </p>
                <Input
                  v-model="medication_3"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="medication_3 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <Input
                  v-model="dosage_3"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="dosage_3 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                  Medication 4
                </p>
                <Input
                  v-model="medication_4"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="medication_4 || '--'"
                />
              </div>
              <div
                class="flex flex-col w-full pt-4 pb-2 border-b border-BlueChalk focus-within:border-b-0"
              >
                <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Dosage</p>
                <Input
                  v-model="dosage_4"
                  type="text"
                  class="border-transparent text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:ring-0 focus:border-2 px-3 py-2 focus:outline-none focus:border-ResolutionBlue"
                  :placeholder="dosage_4 || '--'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="update-profile-btn-wrapper">
      <BaseButton
        @click="updateMedicalInfo"
        type="submit"
        class="w-48 px-8 py-3 bg-ResolutionBlue text-white rounded-3xl hover:bg-DodgerBlue hover:text-white transition-all duration-300"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else>Update profile</span>
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useQuery, useMutation } from "@tanstack/vue-query";
import { push } from "notivue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import InputField from "../../ui/InputField.vue";
import arrowUpIcon from "@/assets/icons/arrow-up-iocn.svg"
import numberUpArrowIcon from "@/assets/images/number-up-arrow.png"
import Input from "@/components/main/ui/Input.vue"
import BaseButton from "@/components/main/ui/BaseButton.vue"

const {
  "user/fetchPatientProfile": fetchPatientProfile,
  "user/updateUserMedicalInfo": updateUserMedicalInfo,
} = mapActions();

const { "user/getPatientProfile": getPatientProfile } = mapGetters();

const openResidentialAddress = ref(true);
const openMedicalHistory = ref(true);
// const isLoading = ref(false);

const weight = ref(0);
const height = ref(0);
const weight_units = ref("kg");
const height_units = ref("ft");
const blood_group = ref("");
const diabetes_type = ref("");
const date_of_diagnosis = ref("");
const insulin_dosage = ref("");
const chronic_disease_1 = ref("");
const date_of_chronic_1_diagnosis = ref("");
const chronic_disease_1_medication = ref("");
const chronic_disease_2 = ref("");
const date_of_chronic_2_diagnosis = ref("");
const chronic_disease_2_medication = ref("");
const chronic_disease_3 = ref("");
const date_of_chronic_3_diagnosis = ref("");
const chronic_disease_3_medication = ref("");
const surgery_1 = ref("");
const date_of_surgery_1 = ref("");
const reason_for_surgery_1 = ref("");
const surgery_2 = ref("");
const date_of_surgery_2 = ref("");
const reason_for_surgery_2 = ref("");
const allergy_1 = ref("");
const allergy_2 = ref("");
const allergy_3 = ref("");
const allergy_4 = ref("");
const medication_1 = ref("");
const dosage_1 = ref("");
const medication_2 = ref("");
const dosage_2 = ref("");
const medication_3 = ref("");
const dosage_3 = ref("");
const medication_4 = ref("");
const dosage_4 = ref("");
const primary_care_physician_name = ref("");
const primary_care_physician_hospital = ref("");
const primary_care_physician_phone = ref("");
const primary_care_physician_email = ref("");
const preferred_hospital = ref("");
const preferred_hospital_city = ref("");
const preferred_hospital_state = ref("");
const preferred_hospital_zip_code = ref("");
const preferred_hospital_street = ref("");
const preferred_hospital_country = ref("");
const preferred_hospital_phone = ref("");

const currentDate = ref(new Date().toISOString().slice(0, 10));

const incrementHeight = () => height.value++;
const decrementHeight = () => {
  if (height.value > 0) {
    height.value--;
  }
};

const incrementWeight = () => weight.value++;
const decrementWeight = () => {
  if (weight.value > 0) {
    weight.value--;
  }
};

// Use vue-query to fetch the profile data
const {
  data: patientProfileData,
  isLoading: isProfileLoading,
  isFetching,
  isSuccess,
  isError,
  refetch,
} = useQuery({
  queryKey: ["patientProfile"],
  queryFn: fetchPatientProfile,
  enabled: false, // Disable automatic fetching
});

// Function to populate fields
const populateFields = (data) => {
  if (!data || !data.person) {
    console.warn("No profile data available");
    return;
  }

  const person = data.person;
  // Populate all fields systematically
  const fieldsMapping = {
    primary_care_physician_name: person.primary_care_physician_name,
    primary_care_physician_hospital: person.primary_care_physician_hospital,
    primary_care_physician_phone: person.primary_care_physician_phone,
    primary_care_physician_email: person.primary_care_physician_email,
    preferred_hospital: person.preferred_hospital,
    preferred_hospital_country: person.preferred_hospital_country,
    preferred_hospital_city: person.preferred_hospital_city,
    preferred_hospital_state: person.preferred_hospital_state,
    preferred_hospital_state: person.preferred_hospital_state,
    height: person.height,
    weight: person.weight,
    blood_group: person.blood_group,
    diabetes_type: person.diabetes_type,
    date_of_diagnosis: person.date_of_diagnosis,
    insulin_dosage: person.insulin_dosage,
    chronic_disease_1: person.chronic_disease_1,
    date_of_chronic_1_diagnosis: person.date_of_chronic_1_diagnosis,
    chronic_disease_1_medication: person.chronic_disease_1_medication,
    chronic_disease_2: person.chronic_disease_2,
    date_of_chronic_2_diagnosis: person.date_of_chronic_2_diagnosis,
    chronic_disease_2_medication: person.chronic_disease_2_medication,
    chronic_disease_3: person.chronic_disease_3,
    date_of_chronic_3_diagnosis: person.date_of_chronic_3_diagnosis,
    chronic_disease_3_medication: person.chronic_disease_3_medication,
    surgery_1: person.surgery_1,
    date_of_surgery_1: person.date_of_surgery_1,
    reason_for_surgery_1: person.reason_for_surgery_1,
    surgery_2: person.surgery_2,
    date_of_surgery_2: person.date_of_surgery_2,
    reason_for_surgery_2: person.reason_for_surgery_2,
    allergy_1: person.allergy_1,
    allergy_2: person.allergy_2,
    allergy_3: person.allergy_3,
    allergy_4: person.allergy_4,
    medication_1: person.medication_1,
    dosage_1: person.dosage_1,
    medication_2: person.medication_2,
    dosage_2: person.dosage_2,
    medication_3: person.medication_3,
    dosage_3: person.dosage_3,
    medication_4: person.medication_4,
    dosage_4: person.dosage_4,
  };
  // Dynamically update refs
  Object.entries(fieldsMapping).forEach(([refName, value]) => {
    if (value !== undefined && value !== null) {
      const refToUpdate = eval(refName);
      refToUpdate.value = value || "";
    }
  });
};

// Fetch profile data on component mount
onMounted(async () => {
  try {
    const { data } = await refetch();
    console.log("medical data", data);
    if (data?.person) {
      populateFields(data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
});

// Watch for changes to patientProfileData
watch(patientProfileData, (newData) => {
  console.log("medical data", newData);
  if (newData && newData.person) {
    populateFields(newData);
  }
});

const { mutate: updateMedicalProfile, isLoading } = useMutation({
  mutationFn: (updateData) => updateUserMedicalInfo(updateData),
  onSuccess: () => {
    push.success("Profile updated successfully.");
  },
  onError: () => {
    push.error("Error updating profile.");
  },
});

const updateMedicalInfo = async () => {
  const updateData = {
    weight: weight.value || undefined || undefined,
    height: height.value || undefined,
    blood_group: blood_group.value || undefined,
    diabetes_type: diabetes_type.value || undefined,
    date_of_diagnosis: date_of_diagnosis.value || undefined,
    insulin_dosage: insulin_dosage.value || undefined,
    chronic_disease_1: chronic_disease_1.value || undefined,
    date_of_chronic_1_diagnosis: date_of_chronic_1_diagnosis.value || undefined,
    chronic_disease_1_medication: chronic_disease_1_medication.value || undefined,
    chronic_disease_2: chronic_disease_2.value || undefined,
    date_of_chronic_2_diagnosis: date_of_chronic_2_diagnosis.value || undefined,
    chronic_disease_2_medication: chronic_disease_2_medication.value || undefined,
    chronic_disease_3: chronic_disease_3.value || undefined,
    date_of_chronic_3_diagnosis: date_of_chronic_3_diagnosis.value || undefined,
    chronic_disease_3_medication: chronic_disease_3_medication.value || undefined,
    surgery_1: surgery_1.value || undefined,
    date_of_surgery_1: date_of_surgery_1.value || undefined,
    reason_for_surgery_1: reason_for_surgery_1.value || undefined,
    surgery_2: surgery_2.value || undefined,
    date_of_surgery_2: date_of_surgery_2.value || undefined,
    reason_for_surgery_2: reason_for_surgery_2.value || undefined,
    allergy_1: allergy_1.value || undefined,
    allergy_2: allergy_2.value || undefined,
    allergy_3: allergy_3.value || undefined,
    allergy_4: allergy_4.value || undefined,
    medication_1: medication_1.value || undefined,
    dosage_1: dosage_1.value || undefined,
    medication_2: medication_2.value || undefined,
    dosage_2: dosage_2.value || undefined,
    medication_3: medication_3.value || undefined,
    dosage_3: dosage_3.value || undefined,
    medication_4: medication_4.value || undefined,
    dosage_4: dosage_4.value || undefined,
    primary_care_physician_name: primary_care_physician_name.value || undefined,
    primary_care_physician_phone: primary_care_physician_phone.value || undefined,
    primary_care_physician_email: primary_care_physician_email.value || undefined,
    primary_care_physician_hospital: primary_care_physician_hospital.value || undefined,
    preferred_hospital: preferred_hospital.value || undefined,
    preferred_hospital_street: preferred_hospital_street.value || undefined,
    preferred_hospital_state: preferred_hospital_state.value || undefined,
    preferred_hospital_zip_code: preferred_hospital_zip_code.value || undefined,
    preferred_hospital_country: preferred_hospital_country.value || undefined,
    preferred_hospital_phone: preferred_hospital_phone.value || undefined,

  };
  
  // Trigger mutation with the updateData object
  updateMedicalProfile(updateData);
};

</script>
