<template>
  <div
    class="absolute top-0 left-0 z-50 w-[99px] bg-white py-1 rounded-sm border border-BlueChalk"
    @mouseleave="$emit('close')"
  >
    <div>
      <a
        :href="downloadablePdf"
        download
        class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
      >
        <img
          :src="downloadDarkIcon"
          alt="download icon"
          class="group-hover:hidden"
        />
        <img
          :src="downloadLightIcon"
          alt="download icon"
          class="hidden group-hover:block"
        />
        <p class="text-xs font-semibold">Download</p>
      </a>

      <div v-if="!isAdmin">
        <div
          class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
          @click="emit('delete')"
        >
          <img
            :src="deleteDarkIcon"
            alt="delete icon"
            class="group-hover:hidden"
          />
          <img
            :src="deleteLightIcon"
            alt="delete icon"
            class="hidden group-hover:block"
          />
          <p class="text-xs font-semibold">Delete</p>
        </div>
      </div>

      <a
        :href="pdf"
        target="_blank"
        class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
      >
        <img
          :src="eyeDarkIcon"
          alt="eye dark icon"
          class="group-hover:hidden"
        />
        <img
          :src="eyeLightIcon"
          alt="eye light icon"
          class="hidden group-hover:block"
        />
        <p class="text-xs font-semibold">View</p>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { toRefs } from 'vue';
import downloadDarkIcon from "@/assets/icons/download-dark.svg"
import downloadLightIcon from "@/assets/icons/download-light.svg"
import deleteDarkIcon from "@/assets/icons/delete-dark.svg"
import deleteLightIcon from "@/assets/icons/delete-light.svg"
import eyeDarkIcon from "@/assets/icons/eye-icon-dark.svg"
import eyeLightIcon from "@/assets/icons/eye-icon-light.svg"

const props = defineProps(['pdf']);
const emit = defineEmits(['close', 'delete']);

const store = useStore();
const isAdmin = computed(() => store.getters['auth/getIsAdmin']);

const downloadablePdf = computed(() => `${props.pdf}?download=true`);
</script>
