<template>
  <ModalWrapper>
    <div class="w-[400px] bg-white rounded-3xl py-8 px-10 relative">
      <div @click="closeReportNoteModal" class="flex justify-end mb-2">
        <span
          class="cursor-pointer w-3 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-DarkJungleGreen before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-DarkJungleGreen after:left-0 after:top-0 after:rounded-sm after:rotate-45"
        ></span>
      </div>
      <h2 class="text-xl font-medium">Note:</h2>
      <p>{{ note }}</p>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { inject } from "vue";
import ModalWrapper from "@/components/main/admin/testCenter/modals/ModalWrapper.vue";

defineProps({
  note: String,
});

const closeReportNoteModal = inject("closeReportNoteModal");
</script>
