<template>
  <div
    class="bg-white border-[0.3px] border-OlsoGrey p-6 md:p-10 h-fit rounded-xl text-center"
  >
    <h3 class="text-ResolutionBlue text-xl font-bold mb-2">
      Book an appointment at any of our wellness centers.
    </h3>
    <p class="text-Charcoal text-sm mb-6">
      Schedule appointment with doctors at your convenience
    </p>

    <div class="text-center">
      <BaseButton
        class="bg-ResolutionBlue py-2 px-12 md:py-[13px] md:px-[52px] rounded-[44px] text-white text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
        @click="openAppointmentModal"
      >
        Book Now
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue";
import BaseButton from "@/components/main/ui/BaseButton.vue"

const openAppointmentModal = inject("openAppointmentModal");
</script>
