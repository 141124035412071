<template>
  <div
    class="flex items-center text-MistBlue text-sm md:text-base border-b-2 border-GreenWhite mb-6 md:gap-20"
  >
    <BaseButton
      v-for="(tab, index) in tabs"
      :key="index"
      @click="handleChange(tab.value)"
      class="py-2.5 px-2.5 relative after:transition-all after:duration-300"
      :class="
        tab.value === status &&
        'after:absolute after:left-0 after:top-full after:bg-ResolutionBlue after:w-full after:h-0.5 after:rounded-sm'
      "
    >
      {{ tab.name }}
    </BaseButton>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import BaseButton from "@/components/main/ui/BaseButton.vue"

defineProps({
  status: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["changeStatus"]);

const tabs = [
  { name: "All Appointments", value: "all" },
  { name: "Upcoming", value: "pending" },
  { name: "Completed", value: "completed" },
  { name: "Expired", value: "expired" },
  { name: "Cancelled", value: "cancelled" },
];

const router = useRouter();

const handleChange = (status) => {
  // Emit the status change event
  emit("changeStatus", status);

  // Update the URL without reloading the page
  router.push({ query: { status } });
};
</script>
