<template>
  <div class="p-4 bg-blue-100 h-[160px] rounded-lg mb-4">
    <div class="flex items-center mb-2 gap-3">
      <div
        class="w-[32px] h-[32px] rounded-full bg-white flex items-center justify-center self-center"
      >
        <img
          :src="getIcon(notification.notification_type)"
          alt="icon"
          class="h-6 w-6"
        />
      </div>
      <p class="font-bold text-xl">
        {{ notification.title }}
      </p>
      <p class="font-bold text-xl">•</p>
      <p class="text-sm font-normal font-urbanist">
        {{ formatRelativeTime(notification.created_at) }}
      </p>
    </div>
    <p class="mb-4 font-normal font-Urbanist">
      {{ notification.sender_text }}
    </p>
    <div
      v-if="notification.notification_type === 'Alert' || notification.notification_type === 'Connection Request'"
      class="flex space-x-2 items-center justify-center"
    >
      <BaseButton @click="approveNotification(notification.id)" class="btn-danger">
        Decline
      </BaseButton>
      <BaseButton @click="deleteNotification(notification.id)" class="btn-primary">
        Approve
      </BaseButton>
    </div>
    <div
      class="flex items-center justify-center"
      v-else-if="notification.notification_type === 'Reminder'"
    >
      <BaseButton @click="handleReminder(notification)" class="btn">
        View appointment
      </BaseButton>
    </div>
    <div
      class="flex items-center justify-center"
      v-else-if="notification.notification_type === 'Announcement'"
    >
      <BaseButton @click="handleAnnouncement(notification)" class="btn">
        Read article
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import formatRelativeTime from "@/utils/formatRelativeTime";
import BaseButton from "@/components/main/ui/BaseButton.vue"

defineProps({
  notification: {
    type: Object,
  },
});

const getIcon = (type) => {
  switch (type) {
    case "Alert":
      return require("@/assets/icons/alert.svg");
    case "Reminder":
      return require("@/assets/icons/reminder.svg");
    case "Announcement":
      return require("@/assets/icons/announcement.svg");
    default:
      return require("@/assets/icons/reminder.svg");
  }
};

const approveNotification = async () => {
};

const deleteNotification = async () => {
};

const handleReminder = () => {
};

const handleAnnouncement = () => {
};
</script>

<style scoped>
.btn {
  @apply bg-BrightBlue/10 rounded w-[170px] h-[32px] text-ResolutionBlue font-semibold;
}
.btn-primary {
  @apply bg-BrightBlue/10 text-ResolutionBlue py-2 px-4 rounded font-semibold h-[32px] w-[134px] flex items-center justify-center;
}
.btn-danger {
  @apply text-ArtyClickRed border border-ArtyClickRed py-2 px-4 rounded h-[32px] w-[134px] flex items-center justify-center;
}
</style>
