<template>
  <div class="flex items-center justify-between gap-3 border-b pb-3">
    <div class="flex items-center cursor-pointer">
      <div class="flex-shrink-0 w-10 h-10">
        <img
          class="w-full h-full rounded-full object-cover"
          :src="
            connections_sent?.patient_recipient?.avatar_url ||
            connections_sent?.health_centre?.avatar_url ||
            noAvatar
          "
          alt=""
        />
      </div>
      <div class="ml-3">
        <p class="font-light whitespace-no-wrap">
          You invited
          <span class="font-bold text-ResolutionBlue">{{
            connections_sent?.patient_recipient?.full_name ||
            connections_sent?.health_centre?.name
          }}</span>
          to be linked as your
          <span class="font-bold">{{ connections_sent?.relationship }}</span>
        </p>
        <div
          class="flex items-center space-x-6 text-SantaGrey font-extralight text-sm"
        >
          <span>
            {{ dateFormatter_2(connections_sent?.created_at) }}
          </span>
          <div class="flex items-center">
            <span>・</span>
            <span> {{ timeFormatter_2(connections_sent?.created_at) }} </span>
          </div>
        </div>
      </div>
    </div>

    <BaseButton
      @click="cancelRequest"
      class="border border-ArtyClickRed px-3 py-2 rounded-3xl text-xs font-medium hover:border-ArtyClickRed hover:bg-ArtyClickRed hover:text-white transition-all duration-300 w-16"
    >
      <span v-if="isPending" class="pl-3">
        <LoadingSpinner />
      </span>
      <span v-else>Cancel</span>
    </BaseButton>
  </div>
</template>

<script setup>
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import { timeFormatter_2 } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue"

const queryClient = useQueryClient();

const props = defineProps(["connections_sent"]);

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const { "accountConnection/cancelSentRequest": cancelSentRequest } =
  mapActions();

const { isPending, mutate } = useMutation({
  mutationFn: (data) => cancelSentRequest(data),
  onError: (error) => {
    if (error?.response?.data?.error) {
      push.error(error.response.data.error);
    } else {
      push.error("Error cancelling sent request");
    }
  },
  onSuccess: (_) => {
    queryClient.invalidateQueries({ queryKey: ["linked-accounts"] });
    push.success("Request cancelled successfully");
  },
});

const cancelRequest = async () => {
  mutate({
    id: props.connections_sent.id,
  });
};
</script>
